.premiumQuality {
    padding: 25px 0;
    /*background-image: url('../../assets/img/quality-bg.png');*/
    background-repeat: no-repeat;
    background-position: top left;
    background-size: 350%;
    background-attachment: fixed;
}

@media screen and (min-width: 767px) {
    .premiumQuality {
        padding: 35px 0;
    }
}

@media screen and (min-width: 992px) {
    .premiumQuality {
        background-size: 100%;
    }
}

@media screen and (min-width: 1200px) {
    .premiumQuality {
        padding-top: 55px;
        padding-bottom: 15px;
    }
}

@media screen and (min-width: 1400px) {
    .premiumQuality {
        padding-top: 75px;
    }
}

.premiumQuality__img {
    margin-left: -20px;
    width: 250px;
}

@media screen and (max-width: 370px) {
    .premiumQuality__img {
        width: 290px;
    }
}

@media screen and (min-width: 376px) {
    .premiumQuality__img {
        margin-left: 0;
    }
}

@media screen and (min-width: 577px) {
    .premiumQuality__img {
        width: 350px;
    }
}

@media screen and (min-width: 767px) {
    .premiumQuality__img {
        width: 420px;
    }
}

.premiumQuality__img img {
    width: 100%;
}

.premiumQuality__wrapper {
    display: flex;
}

.premiumQuality__list {
    padding-left: 10px;
    flex: 1;
}

@media screen and (max-width: 370px) {
    .premiumQuality__list {
        margin-right: 10px;
        padding-left: 10px;
    }
}

@media screen and (min-width: 376px) {
    .premiumQuality__list {
        padding-left: 20px;
    }
}

.premiumQuality__list_item {
    margin-bottom: 22px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    max-width: 120px;
    text-align: left;
    text-transform: uppercase;
    color: rgba(0,139,255,1);
}

@media screen and (max-width: 370px) {
    .premiumQuality__list_item {
        font-size: 10px;
        line-height: 14px;
    }
}

@media screen and (min-width: 360px) and (max-width: 370px) {
    .premiumQuality__list_item {
        margin-bottom: 16px;
    }
}

@media screen and (min-width: 376px) {
    .premiumQuality__list_item {
        max-width: 160px;
    }
}

@media screen and (min-width: 577px) {
    .premiumQuality__list_item {
        margin-bottom: 45px;
    }
}

@media screen and (min-width: 767px) {
    .premiumQuality__list_item {
        font-size: 16px;
        line-height: 22px;
        max-width: 170px;
    }
}

.premiumQuality__list_item.push_system {
    margin-bottom: 50px;
}

@media screen and (max-width: 370px) {
    .premiumQuality__list_item.push_system {
        margin-bottom: 32px;
    }
}

@media screen and (min-width: 360px) and (max-width: 370px) {
    .premiumQuality__list_item.push_system {
        margin-bottom: 40px;
    }
}

@media screen and (min-width: 577px) {
    .premiumQuality__list_item.push_system {
        margin-bottom: 90px;
    }
}

.premiumQuality__list_item.axis {
    margin-bottom: 10px;
}

@media screen and (min-width: 577px) {
    .premiumQuality__list_item.axis {
        margin-bottom: 40px;
    }
}

.premiumQuality__title_bottom {
    margin-top: 55px;
    line-height: 32px;
}

.premiumQuality__header_container {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
}

.premiumQuality__header_img {
    margin-right: 17px;
    width: 72px;
    height: auto;
}

.premiumQuality__header_img img {
    width: 100%;
}

/* New Dis */
.premiumQuality__items {
    margin-top: 25px;
    width: 100%;
}

@media screen and (min-width: 992px) {
    .premiumQuality__items {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}

.premiumQuality__item {
    margin-bottom: 25px;
    width: 100%;
    opacity: 0;
    transform: translateY(100%);
    transition: all 2s;
}

.premiumQuality__item.animate {
    opacity: 1;
    transform: translateY(0);
}

@media screen and (min-width: 992px) {
    .premiumQuality__item {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
        width: 47%;
    }
}

.premiumQuality__item_header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.premiumQuality__item_img {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    padding: 10px;
    width: 88px;
    height: 88px;
    overflow: hidden;
}

@media screen and (min-width: 1200px) {
    .premiumQuality__item_img {
        width: 135px;
        height: 135px;
    }
}

@media screen and (min-width: 1400px) {
    .premiumQuality__item_img {
        margin-right: 20px;
    }
}

.premiumQuality__item_img.empty {
    border: 1px solid rgba(82,114,255,1);
}

.premiumQuality__item_img img {
    width: 100%;
}

.premiumQuality__item h3 {
    margin-bottom: 5px;
    font-family: 'Tussilago', sans-serif;
    font-weight: 700;
    font-size: 12px;
    line-height: 17px;
    text-transform: uppercase;
    color: rgba(82,114,255,1);
}

@media screen and (min-width: 1400px) {
    .premiumQuality__item h3 {
        font-size: 18px;
        line-height: 29px;
        letter-spacing: -0.06px;
    }
}

.premiumQuality__item_info p {
    margin:  0 auto;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: rgba(89,89,89,1);
}

@media screen and (min-width: 1400px) {
    .premiumQuality__item_info p {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.04px;
    }
}