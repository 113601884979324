.collection {
    background: rgb(242, 242, 246);
}

@media screen and (min-width: 1200px) {
    .collection {
        position: relative;
        background-color: #F7F7F7;
    }
}

@media screen and (min-width: 1400px) {
    .collection {
        background-color: transparent;
    }

    .collection::before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        width: 100vw;
        height: 100vh;
        background-image: url('../../assets/img/top_bg.png');
        background-repeat: no-repeat;
        background-position: top center;
        background-size: cover;
        z-index: -1;

    }
}

.collection__dark {
    padding-left: 0;
}

@media screen and (min-width: 1200px) {
    .collection__light {
        position: relative;
    }
}

@media screen and (min-width: 1400px) {
    .collection__dark {
        padding-top: 70px;
    }
}

.zoom {
    position: absolute;
    top: 75px;
    right: 85px;
    width: 46px;
    height: 46px;
    background-image: url('../../assets/img/cursor_zoom.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transition: all .45s;
    cursor: pointer;
    opacity: 0.8;
}

@media screen and (min-width: 577px) {
    .zoom {
        top: 95px;
        right: 115px;
    }
}

@media screen and (min-width: 768px) {
    .zoom {
        top: 125px;
        right: 185px;
    }
}

@media screen and (min-width: 1200px) {
    .zoom {
        right: 105px;
        transition-duration: 0s;
        transition-delay: 0s;
        cursor: none;
        pointer-events: none;
    }
}

.zoom:hover {
    transform: scale(1.2);
}

.zoom:active {
    transform: scale(1);
}

.zoom__img_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 5;
    opacity: 0;
    visibility: hidden;
    transition: all .45s;
}

.zoom__img_wrapper.active {
    opacity: 1;
    visibility: visible;
}

.zoom__img {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    transform: scale(0.7);
    transition: all .45s;
}

.zoom__img_wrapper.active .zoom__img {
    transform: scale(1);
}

.zoom__img,
.zoom__img img {
    width: 100%;
}

.zoom_close {
    position: absolute;
    top: 25px;
    right: 25px;
    width: 30px;
    height: 30px;
}

.zoom_close span {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 100%;
    height: 2px;
    background-color: #fff;
}

.zoom_close span:first-child {
    transform: translate(-50%, -50%) rotate(-45deg);
}

.zoom_close span:last-child {
    transform: translate(-50%, -50%) rotate(45deg);
}

.collection__top {
    padding-top: 0;
    margin-bottom: 40px;
    /*overflow: hidden;*/
}

.collection__top:hover {
    z-index: 3;
}

.collection__top.animate {
    animation: animateSliders 1s;
}

@keyframes animateSliders {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.collection__top_slider,
.collection__top_slider img {
    width: 100%;
}

.collection__top_slider img + div * {
    background: transparent !important;
}

.collection__top_slider img + div:nth-child(2) {
    background: #FFFFFF !important;
}

.collection__top_slider {
    position: relative;
    /*padding: 30px;*/
    transition: all .45s;
    width: 100vw;
    transform: translateX(-24px);
}

@media (min-width: 767px) {
    .collection__top_slider {
        width: 100%;
        transform: none;
    }
}

.collection__top_slider.animate {
    /*animation: animateSliderScale 1s;*/
}

.collection__top_slider img ~ div img {
    width: 1200px !important;
    height: auto !important;
}

/*@keyframes animateSliderScale {*/
/*    0% {*/
/*        transform: scale(0);*/
/*    }*/
/*    100% {*/
/*        transform: scale(1);*/
/*    }*/
/*}*/

@media screen and (min-width: 992px) {
    .collection__top_slider {
        margin: 0 auto;
        width: 100%;
    }
}

@media screen and (min-width: 1200px) {


    .collection__top_slider img {
        cursor: none !important;
    }

    .collection__top_slider:hover .zoom {
        opacity: 0;
    }
}

.artList {
    display: flex;
    justify-content: center;
    margin-top: 5px;
    width: 100vw;
    transform: translateX(-5px);
}

.artList span {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px;
    width: 80px;
    height: 80px;
    background-color: #fff;
    transition: all .45s;
    cursor: pointer;
}

@media screen and (min-width: 1200px) {


    .artList {
        margin-top: 0;
        width: auto;
        transform: none;
    }

    .artList span {
        width: 100px;
        height: 100px;
    }
}

@media screen and (min-width: 1400px) {
    .artList span {
        width: 155px;
        height: 155px;
        padding: 15px;
    }
}

.collection__btn_wrapper {
    margin-top: 50px;
    padding-top: 25px;
    padding-bottom: 25px;
    background-color: #fff;
}

.collection__container {
    position: relative;
    padding-top: 34px;
    background-color: rgba(242, 242, 246, 1);
}

.collection__container.hidden {
    overflow: hidden;
}

.collection__bottom {
    z-index: 2;
}

@media screen and (min-width: 1200px) {
    .collection__btn_wrapper {
        width: 50%;
        margin-left: 0;
        margin-top: -84px;
    }

    .collection__container {
        display: flex;
        flex-direction: row-reverse;
        padding: 0;
    }

    .collection__top,
    .collection__bottom {
        width: 50%;
    }

    .collection__top {
        background: #fff;
        margin-bottom: 0;
    }

    .collection__bottom {
        position: relative;
        margin-bottom: 80px;
        padding-bottom: 80px;
        padding-top: 34px;
        padding-left: 80px;
        background: #fff;
    }

    .collection__bottom::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 475px;
        height: 100%;
        background-color: #F2F2F6;
    }
}

@media screen and (min-width: 1400px) {
    .collection__container {
        background-color: #fff;
    }

    .collection__btn_wrapper {
        margin-top: -76px;
        padding-left: 80px;
        padding-top: 0;
        padding-bottom: 40px;
    }

    .collection__bottom {
        margin-bottom: 170px;
        padding-bottom: 60px;
        padding-top: 0;
    }
}

.artCovers p {
    text-align: center;
}

.artCovers ul {
    justify-content: center;
}
