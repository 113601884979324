.home {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100vh;
    color: #ffffff;
    overflow: hidden;
    -webkit-overflow-scrolling: auto;
}

@supports (-webkit-overflow-scrolling: touch) {
   .home {
       height: 88vh;
   }
}

.home::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    /*background-image: url('../../assets/img/bgblue_top.jpg');*/
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    -webkit-appearance: none;
    width: 100vw;
    height: 100vh;
    z-index: 0;
    transition: box-shadow .45s .4s;
}

.home.showUmbrella {
    overflow: visible;
}

.home header {
    opacity: 0;
    transition: opacity 1s 2s;
}

.home.loadedPage header {
    opacity: 1;
}

.home__title,
.home__btn {
    opacity: 0;
    transform: translateY(100%);
    transition: all .45s 2.2s;
}

.home__title {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-20%,-100px) scale(1.5);
    text-align: right;
    font-family: 'Tussilago', sans-serif;
    font-weight: bold;
    font-size: 15px;
    line-height: 15px;
    /*max-width: 80%;*/
    color: #F9F9F9;
    z-index: 2;
    text-transform: uppercase;
    max-width: 60%;
}

.home__title span {
    transition: all 0s;
}

.home .home__title.delete_storke {
    -webkit-text-fill-color: #fff;
    background-color: transparent;
    transition: all 1s, background-color 0s;
    -webkit-text-stroke-width: 0;
}

@media screen and (min-width: 992px) {
    .home__title {
        max-width: 500px;
    }
}

.home__subtitle {
    position: relative;
    margin-top: auto;
    width: 100%;
    max-width: 80%;
    font-family: 'Tussilago', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: right;
    color: rgba(255,255,255,1);
    z-index: 2;
}

.home__subtitle_item {
    display: block;
    margin-bottom: 15px;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 14px;
    height: 25px;
    overflow: hidden;
    transition: all .25s ease-in, background 0s;
}

.home__subtitle_item.delete_storke,
.home__subtitle_item.delete_storke span {
    transition: all 1s ease 0s;
    overflow: visible;
}

@media screen and (min-width: 1024px) {
    .home__subtitle {
        margin-bottom: 50px;
    }
}

@media screen and (min-width: 1100px) {
    .home__subtitle {
        max-width: 50%;
    }
}

@media screen and (min-width: 1200px) {
    .home__subtitle_wrapper {
        height: 25px;
        margin-bottom: 25px;
    }

    .home__subtitle_wrapper.active {
        height: 0;
    }

    .home__subtitle_item {
        margin-bottom: 0;
    }
}

@media screen and (min-width: 1400px) {
    .home__subtitle {
        margin-bottom: 100px;
    }
}

@media screen and (min-width: 1600px) {
    .home__subtitle {
        margin-bottom: 150px;
    }
}

.home__subtitle_item span {
    display: block;
    transform: translateY(40px);
    transition: all 1s ease-in 3s;
}

.home__subtitle_item:nth-of-type(2) span {
    transition-delay: 2.8s;
}

.home__subtitle_item:nth-of-type(3) span {
    transition-delay: 3s;
}

.home__subtitle_item:nth-of-type(4) span {
    transition-delay: 3.2s;
}

.home__subtitle_item.delete_storke span {
    transition: all 0s;
    transition-delay: 0s;
}

@media screen and (min-width: 577px) {
    .home__subtitle_item {
        font-size: 14px;
        line-height: 16px;
    }
}

@media screen and (min-width: 1024px) {
    .home__subtitle_item {
        margin-bottom: 30px;
    }
}

@media screen and (min-width: 1400px) {
    .home__subtitle_item {
        font-size: 15px;
        line-height: 17px;
    }
}

.home__btn_wrapper {
    display: flex;
    flex-direction: column-reverse;
    margin: 0;
    margin-top: 5px;
    padding: 0;
    z-index: 2;
}

@media screen and (min-width: 767px) {
    .home__btn_wrapper {
        margin-top: 100px;
    }
}

@media screen and (min-width: 1200px) {
    .home__btn_wrapper {
        margin-top: 0px;
    }
}

@media screen and (min-width: 1400px) {
    .home__btn_wrapper {
        margin-top: 100px;
    }
}

.home__btn {
    position: relative;
    margin-bottom: -2px;
    padding: 30px;
    font-family: 'Tussilago', sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: -0.04px;
    text-transform: uppercase;
    text-align: left;
    border-radius: 0;
    border: none;
    width: 100%;
    color: #5272FF;
    background: rgba(255,255,255,1);
    transition-delay: 3s;
    cursor: pointer;
    outline: none;
    z-index: 2;
}

.home__btn::after {
    content: '';
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    -moz-animation: bounce 3s infinite;
    -webkit-animation: bounce 3s infinite;
    animation: bounce 3s infinite;
    background-image: url('../../assets/img/arrow.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transition: all .45s;
}

/*.home__btn:hover::after {*/
/*    */
/*}*/
@-moz-keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        -moz-transform: translateY(-50%);
        transform: translateY(-50%);
    }
    40% {
        -moz-transform: translateY(-30px);
        transform: translateY(-30px);
    }
    60% {
        -moz-transform: translateY(-15px);
        transform: translateY(-15px);
    }
}
@-webkit-keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }
    40% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }
    60% {
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
    }
}
@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }
    40% {
        -moz-transform: translateY(-30px);
        -ms-transform: translateY(-30px);
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }
    60% {
        -moz-transform: translateY(-15px);
        -ms-transform: translateY(-15px);
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
    }
}

@media screen and (min-width: 1200px) {
    .home__btn_wrapper {
        flex-direction: row;
        align-items: center;
        padding: 0 24px;
    }

    .home__btn {
        padding: 30px 40px;
        width: 50%;
        height: 100%;
    }

    .home__btn:hover {
        background-color: rgba(255,255,255,0.9);
    }

    .home__btn.deleteDelay {
        transition-delay: 0s;
    }
}

@media screen and (min-width: 1400px) {
    .home__btn_wrapper {
        margin-left: auto;
        margin-right: auto;
        margin-top: 0;
    }

    .home__btn {
        padding: 50px 60px;
    }
}

/*.home.loadedPage .home__title,*/
.home.loadedPage .home__btn {
    opacity: 1;
    transform: translateY(0);
}

.home.loadedPage .home__title {
    opacity: 1;
}

.home.loadedPage .home__subtitle_item span {
    transform: translateY(0px);
}

/*.home__btn:hover::after {*/
/*    top: 60%;*/
/*}*/

.sliderBtns__wrapper {
    opacity: 0;
    visibility: hidden;
    transition: opacity .45s 3s;
}

.sliderBtns__wrapper.show {
    opacity: 1;
    visibility: visible;
}

@media screen and (min-width: 1200px) {
    .sliderBtns__wrapper {
        display: flex;
        align-items: center;
        flex: 1;
        padding-right: 40px;
        height: 100%;
        background-color: rgba(255,255,255,0.1);
    }
}

.sliderBtns {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    max-width: calc(80% + 20px);
    width: 100%;
    z-index: 2;
}

.sliderBtns__arrow {
    padding: 30px 20px;
    opacity: 0.3;
    transition: all .45s;
    cursor: pointer;
}

.sliderBtns__arrow:hover {
    opacity: 1;
}

.sliderBtns__arrow:active {
    opacity: 0.3;
}

.sliderCounter {
    margin-left: 60px;
}

@media screen and (min-width: 1200px) {
    .sliderCounter {
        display: flex;
    }
}

.sliderCounter span {
    font-family: 'Syncopate', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #FFFFFF;
}

span.sliderCounter__current {
    font-weight: 700;
}

.sliderCounter__sepo {
    display: inline-block;
    margin: 0 10px;
}

@media screen and (min-width: 767px) {
    .home__title {
        transform: translate(-50%,-100px) scale(3);
        max-width: none;
    }
}

@media screen and (min-width: 1023px) {
    .home__title {
        transform: translate(-70%,-100px) scale(4);
    }
}

@media screen and (min-width: 1200px) {
    .home__title {
        left: 50%;
        transform: translate(-50%,-150px) scale(3.5);
        max-width: none;
        background-color: transparent;
        background-size: auto;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-position: 0;
        background-size: 200%;
        -webkit-text-stroke-color: #ffffff;
        -webkit-text-stroke-width: 0.2px;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        background-clip: text;
        overflow: hidden;
        background-color: transparent;
        transition: all 2s 2s, background 1s 2.4s, background-position 4s 2.4s;
        /*background-image: url('../../assets/img/text-bg.png');*/
        /*background-repeat: no-repeat;*/
        /*background-position: center;*/
        /*background-position: 0px 130%;*/
        /*background-size: 100%;*/
    }

    /*.home__title::before {*/
    /*    position: absolute;*/
    /*    content: 'Real art umbrellas';*/
    /*}*/

    .home.loadedPage .home__title {
        /*-webkit-text-stroke-color: transparent;*/
        /*background-color: #fff;*/
        /*background-position: 0px 50%;*/
        background-color: #fff;
    }

    .home .home__title.delete_storke {
        /*-webkit-text-fill-color: transparent;*/
        background-color: transparent;
        transition: all 1s, background-color 0s;
        -webkit-text-stroke-width: 0;
    }
}

@media screen and (min-width: 1400px) {
    .home__title {
        transform: translate(-10%,-150px) scale(5);
    }
}

.home__info {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: 10vh;
}

@media screen and (min-width: 1600px) {
    .home__info {
        position: static;
        margin: 0 auto;
        max-width: 1280px;
        width: 100%;
    }
}

.homeLogo__container {
    display: block;
}

@media screen and (min-width: 1200px) {
    .homeLogo__container {
        position: relative;
        display: none;
    }
}
