.footer {
    position: relative;
    background-color: #2A2B58;
    background-image: url('../../assets/img/bg_bot.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.footerImg {
    display: none;
}
.footerImg img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}
.footerTop {
    padding: 55px 0;
}

.footerBottom {
    padding: 20px 0;
    background-color: rgba(151, 151, 151, .04);
}

.footerBottom__content {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.footerTop__description {
    display: block;
    font-family: 'Montserrat', sans-serif;
    max-width: 400px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -.05px;
    color: #fff;
}

@media screen and (min-width: 1400px) {
    .footer-top__description {
        display: block;
        font-family: 'Montserrat', sans-serif;
        max-width: 450px;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -.05px;
        color: #fff;
    }
}

.footer::before {
    content: '';
    position: absolute;
    left: 0;
    top: 45px;
    transform: rotate(-180deg);
    display: block;
    width: 20px;
    height: 80px;
    background-image: url('../../assets/img/zontjk_logo_bot_big.svg');
    background-repeat: no-repeat;
    background-position: center right;
    background-size: contain;
}

.footer__copyright p {
    font-family: 'Tussilago', sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 15px;
    text-transform: uppercase;
    letter-spacing: -0.04px;
    color: rgba(255, 255, 255, 0.8);
}

.footer__copyright span {
    opacity: 0.4;
    color: rgba(255, 255, 255, 1);
    font-family: "Montserrat", sans-serif;
    font-size: 11px;
    letter-spacing: -0.04px;
    line-height: 19px;
}

.footer__socials {
    display: flex;
    justify-content: center;
    margin: 35px 0;
}

@media screen and (min-width: 1200px) {
    .footer__socials {
        margin: 5px 0;
        margin-left: 10%;
    }

    .footer__socials a {
        padding: 10px;
    }
}

.footer__socials a {
    transition: all .45s;
}

.footer__socials ul {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

@media screen and (min-width: 1200px) {
    .footerBottom__content {
        flex-direction: row;
    }
    .footer__socials ul {
        justify-content: flex-end;
    }
}

.footer__socials ul::before {
    display: none;
}

.footer__socials ul li {
    margin-right: 37px;
    margin-bottom: 0;
}

@media screen and (min-width: 1200px) {
    .footer__socials ul li {
        margin-right: 17px;
        width: 43px;
        height: 43px;
    }


}


.footer a {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: #FFCD00;
    text-decoration: none;
}

.footer__email {
    border: 1px solid rgba(255, 205, 0, .2);
    display: inline-block;
    padding: 5px;
    border-radius: 6px;
    text-decoration: underline !important;
    position: relative;
    transition: all .3s;
    z-index: 1;
}
.footer__email::before,.footer__email::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 0;
    display: block;
    transition: all .3s;
    top: 0;
    z-index: -1;
    border-radius: 6px;
    background: rgba(255, 205, 0, .8);
}
.footer__email::before {
    left: 0;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}
.footer__email::after {
    right: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}
.footer__email:hover {
    color: #ffffff;
}
.footer__email:hover::before {
    width: 50%;
}
.footer__email:hover::after {
    width: 50%;
}
@media screen and (min-width: 1200px) {
    .footer a:not(:last-child) {
        margin-right: 20px;
    }
}

.footer a:hover {
    text-decoration: underline;
}

.footer__feedback {
    margin-top: 22px;
    display: flex;
    align-items: center;
    /*justify-content: space-between;*/
}

@media screen and (max-width: 1200px) {
    .footer__feedback {
        flex-direction: column;
        align-items: center;
    }
}

@media screen and (min-width: 1200px) {
    .footer__feedback {
        /*justify-content: center;*/
    }
    .footerImg {
        display: block;
        position: absolute;
        right: 10%;
        bottom: 0;
        width: 410px;
        z-index: 4;
    }
}

