.storySection {
    background-repeat: no-repeat;
    background-position: -180px 30px;
    background-size: 100%;
}

.storySection.animateZlata {
    transform: scale(2);
}

.storySection__wrapper {
    position: relative;
    padding-top: 300px;
    opacity: 1;
    visibility: visible;
    transition: all 1s linear;
}

.storySection__wrapper::before {
    content: '';
    position: absolute;
    top: 0;
    left: -40%;
    display: block;
    width: 100%;
    height: 500px;
    filter: grayscale(1);
    opacity: 0.4;
    background-image: url('../../assets/img/zlata_bg.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.storySection__wrapper.animate::before {
    filter: grayscale(0);
}

@media screen and (min-width: 577px) {
    .storySection__wrapper {
        padding-top: 450px;
    }
}

@media screen and (min-width: 680px) {
    .storySection {
        background-size: 100%;
    }
}

@media screen and (min-width: 680px) {
    .storySection {
        background-size: 60%;
    }

    .storySection__wrapper {
        padding-top: 300px;
    }
}

@media screen and (min-width: 1100px) {
    .storySection__wrapper {
        padding-bottom: 100px;
    }
}

@media screen and (min-width: 1200px) {
    .storySection {
        padding-bottom: 0;
        background-size: 33%;
        background-position: 50px;
    }

    .storySection__wrapper::before {
        top: 40px;
        left: -51%;
        height: 100%;
        background-size: 50%;
        transition: all 1s linear;
    }
}

@media screen and (min-width: 1400px) {
    .storySection {
        background-size: 45%;
        background-position: 30px 160px;
    }

    .storySection__wrapper {
        padding-top: 170px;
    }

    .storySection__wrapper::before {
        left: -57%;
    }
}

@media screen and (min-width: 1600px) {
    .storySection {
        background-position: 10% 160px;
        background-size: 720px;
    }

    .storySection__wrapper::before {
        left: -40%;
    }
}

.storySection__images_top {
    display: flex;
}

.storySection__images_top figure {
    flex: 1;
    overflow: hidden;
    max-height: 150px;
    height: auto;
}

@media screen and (max-width: 374px) {
    .storySection__images_top figure {
        max-height: 130px;
    }
}

@media screen and (min-width: 577px) {
    .storySection__images_top figure {
        max-height: 240px;
    }
}

@media screen and (min-width: 992px) {
    .storySection__images_top figure {
        max-height: 370px;
    }
}

.storySection__images_top img {
    width: 100%;
    max-width: none;
    min-width: auto;
    height: auto;
}

@media screen and (min-width: 376px) {
    .storySection__images_top img {
        width: 100%;
        height: auto;
    }
}


.storySection__images_bottom figure {
    height: 300px;
    overflow: hidden;
}

@media screen and (min-width: 577px) {
    .storySection__images_bottom figure {
        height: 600px;
    }
}

@media screen and (min-width: 1025px) {
    .storySection__images_bottom figure {
        height: 750px;
    }
}

.storySection__images_bottom img {
    width: 100%;
}

@media screen and (min-width: 376px) {
    .storySection__images_bottom img {
        width: 100%;
        height: auto;
    }
}

.storySection__info {
    position: relative;
    padding: 25px 0;
    background-color: #fff;
}


@media screen and (min-width: 577px) {
    .storySection__info {
        margin-left: auto;
        width: 70%;
    }
}

@media screen and (min-width: 1200px) {
    .storySection__info {
        padding: 50px 0;
        width: 50%;
    }
}

@media screen and (min-width: 1400px) {
    .storySection__info {
        padding: 120px 0;
    }
}

.storySection__info_text p {
    font-family: 'Istok Web', sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 26px;
    letter-spacing: -0.04px;
    color: rgba(89,89,89,1);
}

@media screen and (min-width: 1400px) {
    .storySection__info_text p {
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 32px;
        letter-spacing: -0.05px;
        color: #595959;
    }
}

.storySection__author {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 45px;
}

.storySection__author_img {
    margin-right: 15px;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    overflow: hidden;
}

@media screen and (min-width: 767px) {
    .storySection__author_img {
        width: 70px;
        height: 70px;
    }
}

.storySection__author_img img,
.storySection__author_signature img{
    width: 100%;
}

.storySection__author_signature {
    display: flex;
    align-items: center;
    margin: 20px 0;
    width: 250px;
}

@media screen and (min-width: 1200px) {
    .storySection__author_signature {
        transform: translateX(-60px);
    }
}

@media screen and (min-width: 1400px) {
    .storySection__author_signature {
        width: 660px;
        transform: translateX(-200px);
    }
}

.storySection__btn {
    margin-top: 30px;
}

@media screen and (min-width: 767px) {
    .storySection__btn {
        width: 200px;
    }
}

@media screen and (min-width: 1400px) {
    .storySection__btn {
        margin-top: 80px;
    }

    .storySection__container {
        padding: 0 60px 0 70px;
    }
}
