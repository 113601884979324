/* FONTS */
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:400,600,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Istok+Web:400,400i,700,700i&display=swap&subset=cyrillic");
@import url("https://fonts.googleapis.com/css?family=Rubik:400,500,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Syncopate:400,700&display=swap");

@font-face {
    font-family: 'Avenir';
    src: url('assets/fonts/Avenir/AvenirLT-Black.eot');
    src: url('assets/fonts/Avenir/AvenirLT-Black.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/Avenir/AvenirLT-Black.woff') format('woff'),
    url('assets/fonts/Avenir/AvenirLT-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'SFDisplay';
    src: url('assets/fonts/SF/HelveticaNeueDeskInterface-Bold.eot');
    src: url('assets/fonts/SF/HelveticaNeueDeskInterface-Bold.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/SF/HelveticaNeueDeskInterface-Bold.woff') format('woff'),
    url('assets/fonts/SF/HelveticaNeueDeskInterface-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'SFDisplay';
    src: url('assets/fonts/SF/HelveticaNeueDeskInterface-Regular.eot');
    src: url('assets/fonts/SF/HelveticaNeueDeskInterface-Regular.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/SF/HelveticaNeueDeskInterface-Regular.woff') format('woff'),
    url('assets/fonts/SF/HelveticaNeueDeskInterface-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SFDisplay';
    src: url('assets/fonts/SF/HelveticaNeueDeskInterface-Thin.eot');
    src: url('assets/fonts/SF/HelveticaNeueDeskInterface-Thin.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/SF/HelveticaNeueDeskInterface-Thin.woff') format('woff'),
    url('assets/fonts/SF/HelveticaNeueDeskInterface-Thin.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Tussilago';
    src: url('assets/fonts/Tussilago/Tussilago.eot');
    src: url('assets/fonts/Tussilago/Tussilago.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/Tussilago/Tussilago.woff') format('woff'),
    url('assets/fonts/Tussilago/Tussilago.woff2') format('woff2'),
    url('assets/fonts/Tussilago/Tussilago.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

.App {
    display: flex;
    flex-direction: column;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

ul, figure, p, h1, h2, h3, h4, h5 {
    margin: 0;
    padding: 0;
}

ul {
    list-style-type: none;
}

.container {
    margin: 0 auto;
    padding: 0 24px;
}

@media screen and (min-width: 1200px) {
    .container {
        position: relative;
        width: 100%;
        max-width: 1280px;
    }
}

body, html {
    overflow-x: hidden;
}

body.no-scroll,
html.no-scroll {
    overflow: hidden;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    -moz-scrollbars-none: hidden;
    -moz-hidden-unscrollable: hidden;
    -ms-touch-action: none;
    touch-action: none;
}

@media screen and (min-width: 1200px) {
    body.no-scroll,
    html.no-scroll {
        margin-right: 10px;
    }
}

.collectionSection {
    background-color: #fff;
    z-index: 2;
}

body.no-scroll::-webkit-scrollbar,
html.no-scroll::-webkit-scrollbar {
    overflow: hidden !important;
}

body.allowScroll,
html.allowScroll {
    overflow-x: hidden;
}

/*@media screen and (min-width: 1400px) {*/
/*    body {*/
/*        background-color: #f7f7f7;*/
/*    }*/
/*}*/

* {
    box-sizing: border-box;
}

.title {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 36px;
    line-height: 51px;
    text-align: center;
    color: #E3E3E3;
}

@media screen and (max-width: 370px) {
    .title {
        line-height: 40px;
    }
}

@media screen and (min-width: 577px) {
    .title {
        font-size: 38px;
        line-height: 54px;
    }
}

@media screen and (min-width: 767px) {
    .title {
        margin-bottom: 10px;
        font-size: 44px;
    }
}

@media screen and (min-width: 992px) {
    .title {
        font-size: 57px;
    }
}

.subtitle {
    margin: 0 auto;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    text-align: center;
    font-size: 14px;
    line-height: 23px;
    max-width: 266px;
    color: #008BFF;
}

@media screen and (min-width: 577px) {
    .subtitle {
        font-size: 16px;
        max-width: 300px;
    }
}

@media screen and (min-width: 767px) {
    .subtitle {
        font-size: 18px;
    }
}

@media screen and (min-width: 992px) {
    .subtitle {
        font-size: 20px;
        max-width: 500px;
    }
}


.drop_btn {
    position: relative;
    display: block;
    padding: 10px 35px;
    font-family: 'Tussilago', sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.04px;
    text-decoration: none;
    text-transform: uppercase;
    width: max-content;
    color: rgba(82, 114, 255, 1);
    transition: all .45s;
}

@media screen and (min-width: 1400px) {
    .drop_btn {
        font-size: 14px;
        line-height: 15px;
    }
}

.drop_btn__circle {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
}

.drop_btn__circle::after,
.drop_btn__circle::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 50%;
    height: 100%;
    border-radius: 0 100% 100% 0 / 0 50% 50% 0;
    background: rgba(82, 114, 255, 1);
    transform-origin: center left;
    transition: all .45s;
    z-index: -1;
}

.drop_btn:hover {
    padding-left: 20px;
}

.drop_btn:hover .drop_btn__circle::before {
    transform: rotate(360deg);
}

.umbrella {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw !important;
    /*max-height: 60vh;*/
    height: auto;
    opacity: 0;
    transition: all .5s, opacity 2s 4s;
}

@supports (-webkit-overflow-scrolling: touch) {
    .umbrella {
        height: 88vh;
    }
}

.umbrella .slick-slider {
    height: 100%;
}

.umbrella .slick-dots {
    bottom: 105px;
    width: max-content;
    left: 70px;
}

.umbrella .slick-dots li {
    margin: 0 2px;
}

.umbrella .slick-dots li.slick-active button:before {
    color: rgba(255, 255, 255, 1);
}

.umbrella .slick-dots li button:before,
.umbrella .slick-dots li button:focus:before,
.umbrella .slick-dots li button:hover:before {
    opacity: 0.3;
    color: rgba(255, 255, 255, 1);
}

@media screen and (min-width: 680px) {
    .umbrella {
        /*width: 80%;*/
    }
}

@media screen and (min-width: 1024px) {
    .umbrella {
        /*top: 10%;*/
        /*max-width: 760px;*/
    }

    .umbrella .slick-dots {
        bottom: 235px;
    }
}

@media screen and (min-width: 1100px) {
    .umbrella {
        right: 40px;
        left: auto;
        /*width: 50%;*/
    }
}

@media screen and (min-width: 1200px) {
    .umbrella {
        /*top: 20%;*/
        max-height: none;
        /*max-width: 500px;*/
        transform: translateX(0);
        right: 0;
    }
}

@media screen and (min-width: 1400px) {
    .umbrella {
        /*max-width: 660px;*/
        top: 0;
        right: 0;
    }
}

.umbrella.active {
    opacity: 1;
}

.umbrella img {
    /*margin-left: -70px;*/
    max-height: 100vh;
    height: 90vh;
    transform: translateX(-60%);
    object-fit: cover;
    width: 250%;
}

@media screen and (min-width: 1200px) {
    .umbrella img {
        margin-left: 0;
        height: 100vh;
    }
}

@media screen and (min-width: 768px) {
    .umbrella img {
        transform: translateX(0%);
        width: 100%;
    }
}

.section-description {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 23px;
    color: rgba(0, 139, 255, 1);
}

@media screen and (min-width: 577px) {
    .section-description {
        font-size: 13px;
    }
}

@media screen and (min-width: 767px) {
    .section-description {
        font-size: 16px;
    }
}

.modalWrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transition: all .45s;
}

.modalWrapper.show {
    opacity: 1;
    visibility: visible;
}

.slick-dots li button:before,
.slick-dots li button:focus:before,
.slick-dots li button:hover:before {
    opacity: 0.2;
    color: rgba(255, 255, 255, 1);
}

.slick-dots li.slick-active button:before {
    color: rgba(255, 255, 255, 1);
    opacity: 1;
}

@media screen and (min-width: 1200px) {
    .slick-dots li button:before,
    .slick-dots li button:focus:before,
    .slick-dots li button:hover:before {
        opacity: 0.2;
        color: rgba(0, 139, 255, 1);
    }

    .slick-dots li.slick-active button:before {
        color: rgba(0, 139, 255, 1);
        opacity: 1;
    }
}

.collection__top_slider .slick-dots li button:before,
.collection__top_slider .slick-dots li button:focus:before,
.collection__top_slider .slick-dots li button:hover:before {
    opacity: 0.2;
    color: rgba(0, 139, 255, 1);
}

.collection__top_slider .slick-dots li.slick-active button:before {
    color: rgba(0, 139, 255, 1);
    opacity: 1;
}

.commentsSection .slick-dots li button:before,
.commentsSection .slick-dots li button:focus:before,
.commentsSection .slick-dots li button:hover:before {
    opacity: 0.2;
    color: rgba(0, 139, 255, 1);
}

.commentsSection .slick-dots li.slick-active button:before {
    color: rgba(0, 139, 255, 1);
    opacity: 1;
}

/* ART LIST */
.artList__slider {
    justify-content: center;
}

.artList__slider * {
    outline: none;
}

.artList__slider .slick-track {
    /*padding: 12px 0 0;*/
}

ul.artList__slider .slick-track {
    display: flex;
    /*justify-content: center;*/
}

ul.artList__slider .slick-slide {
    position: relative;
}

ul.artList__slider li.active span,
ul.artList__slider li:hover span {
    transform: translateY(-12px);
}

ul.artList__slider li {
    position: relative;
    width: 80px !important;
    transition: all .45s;
    border-top: 1px solid rgba(234, 234, 234, 1);
    border-left: 1px solid rgba(234, 234, 234, 1);
    margin-right: 6px;
}

@media screen and (min-width: 1200px) {
    ul.artList__slider li {
        width: 100px !important;
        margin-right: 0;
    }
}

@media screen and (min-width: 1400px) {
    ul.artList__slider li {
        width: 155px !important;

    }
}

ul.artList__slider li::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 0;
    height: 1px;
    background-color: #5272FF;
    transition: all .45s;
}

ul.artList__slider li.active::after {
    width: 100%;
}

ul.artList__slider li img {
    width: 56px;
    height: auto;
}

@media screen and (min-width: 1200px) {
    ul.artList__slider li img {
        width: 100%;
    }
}

.artList__slider .slick-slider {
    width: 100%;
}

.slick-slider * {
    outline: none;
}

/* Comments Section */
.commentsSection .slick-track {
    display: flex;
}

.commentsSection .slick-dots {
    bottom: -35px;
}

.logo {
    position: absolute;
    right: auto;
    left: -20px;
    top: 35%;
    display: flex;
    justify-content: flex-end;
    padding: 0;
    width: 40px;
    height: 78px;
    border: none;
    cursor: pointer;
    background: transparent;
    z-index: 3;
    transition: all .15s;
    outline: none;
}

.logo span {
    display: block;
    width: 20px;
    height: 78px;
    pointer-events: none;
}

@media screen and (min-width: 1200px) {
    .logo {
        position: fixed;
        right: -18px;
        left: auto;
    }

    .logo:hover {
        right: -10px;
    }

    .logo.up {
        right: -50px;
    }

    .logo span {
        background-color: #FFCD00;
    }
}

.logo::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 20px;
    height: 80px;
    border: none;
    background-color: transparent;
    background-image: url('./assets/img/zontjk_logo_bot_small.svg');
    background-repeat: no-repeat;
    background-position: top;
    background-size: contain;
    cursor: pointer;
    z-index: -1;
    pointer-events: none;
}

.logo_top__wrapper {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 24px;
    max-width: 970px;
    width: 100%;
    z-index: 3;
}

@media screen and (min-width: 1200px) {
    .logo_top__wrapper {
        padding-left: 0;
        padding-right: 0;
    }
}

.logo_top {
    position: absolute;
    top: -10px;
    display: block;
    padding: 0;
    width: 74px;
    height: 10px;
    border: none;
    background-color: #FFCD00;
    cursor: pointer;
    transition: all .15s;
    outline: none;
}

@media screen and (min-width: 1200px) {
    .logo_top:hover {
        top: -5px;
    }

    .logo_top.up {
        top: -48px;
    }
}

.logo_top::before {
    content: '';
    position: absolute;
    top: 100%;
    left: -10px;
    display: block;
    width: 20px;
    height: 80px;
    background-image: url('./assets/img/zontjk_logo_bot_big.svg');
    background-repeat: no-repeat;
    background-position: top center;
    background-size: contain;
    transform: rotate(-90deg) translateX(-10px);
    transform-origin: top;
}

/**
Preloader
 */
@media (max-width: 576px) {

    #preloader {
        transform: translateY(-75px);
    }
}

div.my-preloader div {
    -webkit-animation-delay: 0s;
    -moz-animation-delay: 0s;
    -o-animation-delay: 0s;
    animation-delay: 0s;
    border: none;
    background: #5272FF;
}

/**
Animation
*/
.animate_to_top {
    transform: translateY(300%);
    opacity: 0;
    transition: all 2s ease;
}

.animate_to_top.animate {
    transform: translateY(0%);
    opacity: 1;
}

/**
Stores
 */
@media screen and (max-width: 1199px) {
    .stores__list_parallax .parallax-inner {
        -webkit-transform: none !important;
        -moz-transform: none !important;
        -ms-transform: none !important;
        -o-transform: none !important;
        transform: none !important;
    }
}

.Typist {
    position: relative;
}

.Cursor--blinking {
    position: absolute;
    right: -10px;
    bottom: 0;
    margin-bottom: 0 !important;
    animation: animateBlinkibg 0.5s linear alternate infinite;
}

@keyframes animateBlinkibg {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.aboutPage ~ #wheretobuy {
    background: #FFFFFF;
}

.collection__top_slider {
    z-index: 0;
}

.collection__top_slider .slick-list {
    overflow: hidden;
}

.collection__top_slider .slick-list:hover {
    overflow: visible;
}

.collection__top_slider .slick-track {
    pointer-events: none;
}

.collection__top_slider .slick-slide {
    opacity: 0;
    visibility: hidden;
    transition: all .45s;
}

.collection__top_slider .slick-current {
    opacity: 1;
    visibility: visible;
}

.collection__top_slider .slick-slide * {
    cursor: none !important;
}

.collection__top_slider .slick-slide {
    pointer-events: all;
}

.collection__top_slider .slick-current .zoomImageRoot div {
    background: rgba(255, 255, 255, 0.4) !important;
}

.modalSlider {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.8);
    opacity: 0;
    visibility: hidden;
    z-index: 5;
    transition: all .45s;
}

.modalSlider.show {
    opacity: 1;
    visibility: visible;
}

.modalSlider img {
    margin: 0 auto;
    width: auto;
    max-height: 80vh;
}

.modalSlider__big {
    margin: 0 auto;
    margin-bottom: 30px;
    width: 50vw;
    max-height: 80vh;
}

.modalSlider__big .slick-slide {
    opacity: 0;
    pointer-events: none;
    cursor: default;
    transition: all .45s;
}

.modalSlider .slick-current {
    pointer-events: all;
    cursor: pointer;
    opacity: 1;
}

.modalSlider__small ul li span {
    width: 95px;
    height: 95px;
    background: rgba(255, 255, 255, 0.5);
}

.modalSlider__small ul.artList__slider li {
    width: 95px !important;
}

.modalSlider__small span:hover,
.modalSlider__small .slick-current span {
    background: rgba(255, 255, 255, 0.8);
}

@media screen and (min-width: 1200px) {
    .largeImage {
        left: -100% !important;
        width: 400px !important;
        z-index: 3;
    }
}

.mainSlider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.mainSlider .slick-slider,
.mainSlider .slick-list {
    height: 100%;
}

.mainSlider .slick-slide {
    position: relative;
    opacity: 0;
    visibility: hidden;
    transition: all .45s;
}

.mainSlider .slick-current {
    opacity: 1;
    visibility: visible;
}

.umbrella__wrapper {
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
    background-size: cover;
    background-image: url('./assets/img/mainSlider/landbg.jpg');
}

.umbrella__wrapper.isBackToHome {
    background: #f7f7f7;
}

@media screen and (min-width: 1600px) {
    .umbrella__wrapper {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        width: 100vw !important;
    }

    .umbrella__container {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        /*max-width: 1280px;*/
    }
}

#preloader {
    -webkit-transition: all 0.5s ease-in;
    transition: all 0.5s ease-in;
}

.mainSlider .slick-dots {
    bottom: 100px;
    left: 0;
    max-width: max-content;
    z-index: 2;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                     supported by Chrome and Opera */
}

.homePage {
    opacity: 0;
    transition: all 2s;
}

.homePage.isBackToHome + #wheretobuy {
    opacity: 0;
}

.homePage.mounted {
    opacity: 1;
}

.homePage.unmounted .storySection__info {
    opacity: 0;
}

.homePage.mounted .storySection__wrapper::before {
    left: -40%;
}

/*.homePage.mounted .storySection__wrapper.animateZlata::before {*/
/*    left: -51%;*/
/*}*/

.homePage.mounted .storySection__wrapper.animateZlata,
.homePage.unmounted .storySection__wrapper::before {
    opacity: 0.4;
}

.homePage.mounted .storySection__wrapper::before {
    opacity: 1;
}

.aboutPage.unmounted * {
    opacity: 0;
    visibility: hidden;
    transition: all 1s;
    /*height: 0;*/
}

.aboutPage.unmounted::before {
    opacity: 0.4;
    height: 800px;
    visibility: visible;
}

div.unmounted {
    order: -1;
}

.aboutPage + section .container {
    margin: 0 auto;
    padding: 0 24px;
    max-width: 970px;
    width: 100%;
}

@media screen and (min-width: 1400px) {
    .homePage.mounted .storySection__wrapper.animateZlata::before {
        left: -57%;
    }
}

@media screen and (min-width: 1600px) {
    .homePage.mounted .storySection__wrapper.animateZlata::before {
        left: -75%;
    }
}
