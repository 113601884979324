.commentsSection {
    margin-top: 40px;
    background-image: url('../../assets/img/feedback-bg.jpg');
    background-repeat: no-repeat;
    background-position: 50% -50px;
    background-size: cover;
}

@media screen and (min-width: 1200px) {
    .commentsSection {
        padding-bottom: 150px;
        background-position: 50% -80px;
    }
}

@media screen and (min-width: 1400px) {
    .commentsSection {
        background-position: 0 -120px;
    }
}

.commentsSection__header {
    padding-bottom: 35px;
}

@media screen and (min-width: 767px) {
    .commentsSection__header {
        padding: 35px 0;
    }
}

@media screen and (min-width: 1400px) {
    .commentsSection__header {
        padding: 90px 0;
    }
}

.commentsSection__header_container {
    display: flex;
    flex-direction: column;
}

.commentsSection__header_img {
    display: flex;
    align-items: center;
    margin-right: 24px;
    width: 72px;
    height: auto;
}

@media screen and (min-width: 767px) {
    .commentsSection__header_img {
        margin-bottom: 25px;
    }
}

.commentsSection__header_img img {
    width: 100%;
}

.comments {
    padding-bottom: 120px;
}

@media screen and (min-width: 1100px) {
    .comments {
        display: flex;
    }
}

@media screen and (min-width: 1200px) {
    .comments {
        margin: 0 auto;
        padding: 0 24px;
        max-width: 1280px;
        width: 100%;
    }
}

.comment__header {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    padding: 0 30px;
}

.comment__header::before {
    content: '';
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    width: 105%;
    height: 1px;
    background-color: rgba(82,114,255,1);
}

.comment__img {
    margin-right: 15px;
    width: 53px;
    height: 53px;
    border-radius: 50%;
    overflow: hidden;
}

.comment__img img {
    width: 100%;
}

.comment__author {
    font-family: 'Tussilago', sans-serif;
    font-weight: bold;
    letter-spacing: -0.06px;
    font-size: 18px;
    line-height: 24px;
    color: rgba(82,114,255,1);
}

.comment__body {
    padding: 0 30px;
}

.comment__body p {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 14px;
    line-height: 23px;
    letter-spacing: -0.04px;
    color: rgba(89,89,89,1);
}

@media screen and (min-width: 1400px) {
    .comment__body p {
        font-size: 16px;
        line-height: 25px;
    }
}

.commentsSection__footer {
    padding: 15px 0;
    border: 1px solid rgba(255,255,255,0.12);
    background-color: rgba(52,61,115,1);
}

@media screen and (min-width: 767px) {
    .commentsSection__footer {
        padding: 35px 0;
    }
}

.comments__btn {
    position: relative;
    width: 100px;
    height: 50px;
    border: 1px solid #4A4F70;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: all .45s;
}

@media screen and (min-width: 767px) {
    .comments__btn {
        width: 150px;
    }
}

.comments__btn_prev {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
}

.comments__btn_next {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
}

.comments__btn_next::before,
.comments__btn_prev::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: block;
}

.comments__btn_next:hover::before,
.comments__btn_prev:hover::before {
    border-left-color: #ffffff;
    border-right-color: #ffffff;
}

.comments__btn_next::before {
    right: 40px;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-left: 7px solid #008EFF;
    border-bottom: 5px solid transparent;
}

.comments__btn_prev::before {
    right: auto;
    left: 40px;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-right: 7px solid #008EFF;
    border-bottom: 5px solid transparent;
}

.comments__btn:hover {
    border-color: #008EFF;
    background-color: #008EFF;
}

.comment {
    margin: 0 7px;
    padding: 30px 0;
    width: 90vw;
    background-color: rgba(255,255,255,1);
}

@media screen and (min-width: 768px) {
    .comment {
        width: 70vw;
    }
}

@media screen and (min-width: 1200px) {
    .comment {
        opacity: 0;
        transition: all 2s;
    }

    .comment:nth-child(1) {
        transform: translateX(-100%);
    }

    .comment:nth-child(2) {
        transform: translateX(0) scale(0);
    }
    .comment:nth-child(3) {
        transform: translateX(100%);
    }

    .comment.animate {
        opacity: 1;
        transform: translateX(0%) scale(1);
        /*animation: commentScale 1s ease-in-out both;*/
    }

    .comment:first-of-type {
        animation-delay: 0.25s;
    }

    .comment:last-of-type {
        animation-delay: 0.5s;
    }

    /*@keyframes commentScale {*/
    /*    0% {*/
    /*        transform: scale(0);*/
    /*        opacity: 0;*/
    /*    }*/
    /*    60% {*/
    /*        transform: scale(1.1);*/
    /*        opacity: 1*/
    /*    }*/
    /*    80% {*/
    /*        transform: scale(0.95);*/
    /*        opacity: 1;*/
    /*    }*/
    /*    100% {*/
    /*        transform: scale(1);*/
    /*        opacity: 1;*/
    /*    }*/
    /*}*/
}
