@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Istok+Web:400,400i,700,700i&display=swap&subset=cyrillic);
@import url(https://fonts.googleapis.com/css?family=Rubik:400,500,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Syncopate:400,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* FONTS */

@font-face {
    font-family: 'Avenir';
    src: url(/static/media/AvenirLT-Black.bf8c68da.eot);
    src: url(/static/media/AvenirLT-Black.bf8c68da.eot?#iefix) format('embedded-opentype'),
    url(/static/media/AvenirLT-Black.1f42bb22.woff) format('woff'),
    url(/static/media/AvenirLT-Black.3fcc4b81.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'SFDisplay';
    src: url(/static/media/HelveticaNeueDeskInterface-Bold.442f7035.eot);
    src: url(/static/media/HelveticaNeueDeskInterface-Bold.442f7035.eot?#iefix) format('embedded-opentype'),
    url(/static/media/HelveticaNeueDeskInterface-Bold.ddc57cbf.woff) format('woff'),
    url(/static/media/HelveticaNeueDeskInterface-Bold.f9bc3bd1.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'SFDisplay';
    src: url(/static/media/HelveticaNeueDeskInterface-Regular.e31ad6de.eot);
    src: url(/static/media/HelveticaNeueDeskInterface-Regular.e31ad6de.eot?#iefix) format('embedded-opentype'),
    url(/static/media/HelveticaNeueDeskInterface-Regular.b270d7a5.woff) format('woff'),
    url(/static/media/HelveticaNeueDeskInterface-Regular.99f1ad50.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SFDisplay';
    src: url(/static/media/HelveticaNeueDeskInterface-Thin.4d945ff5.eot);
    src: url(/static/media/HelveticaNeueDeskInterface-Thin.4d945ff5.eot?#iefix) format('embedded-opentype'),
    url(/static/media/HelveticaNeueDeskInterface-Thin.411a32e3.woff) format('woff'),
    url(/static/media/HelveticaNeueDeskInterface-Thin.2ca1641d.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Tussilago';
    src: url(/static/media/Tussilago.ac3c3d4a.eot);
    src: url(/static/media/Tussilago.ac3c3d4a.eot?#iefix) format('embedded-opentype'),
    url(/static/media/Tussilago.dac5cf22.woff) format('woff'),
    url(/static/media/Tussilago.19a38dcc.woff2) format('woff2'),
    url(/static/media/Tussilago.2099223a.ttf) format('truetype');
    font-weight: 700;
    font-style: normal;
}

.App {
    display: flex;
    flex-direction: column;
}

.App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

ul, figure, p, h1, h2, h3, h4, h5 {
    margin: 0;
    padding: 0;
}

ul {
    list-style-type: none;
}

.container {
    margin: 0 auto;
    padding: 0 24px;
}

@media screen and (min-width: 1200px) {
    .container {
        position: relative;
        width: 100%;
        max-width: 1280px;
    }
}

body, html {
    overflow-x: hidden;
}

body.no-scroll,
html.no-scroll {
    overflow: hidden;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    -moz-scrollbars-none: hidden;
    -moz-hidden-unscrollable: hidden;
    touch-action: none;
}

@media screen and (min-width: 1200px) {
    body.no-scroll,
    html.no-scroll {
        margin-right: 10px;
    }
}

.collectionSection {
    background-color: #fff;
    z-index: 2;
}

body.no-scroll::-webkit-scrollbar,
html.no-scroll::-webkit-scrollbar {
    overflow: hidden !important;
}

body.allowScroll,
html.allowScroll {
    overflow-x: hidden;
}

/*@media screen and (min-width: 1400px) {*/
/*    body {*/
/*        background-color: #f7f7f7;*/
/*    }*/
/*}*/

* {
    box-sizing: border-box;
}

.title {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 36px;
    line-height: 51px;
    text-align: center;
    color: #E3E3E3;
}

@media screen and (max-width: 370px) {
    .title {
        line-height: 40px;
    }
}

@media screen and (min-width: 577px) {
    .title {
        font-size: 38px;
        line-height: 54px;
    }
}

@media screen and (min-width: 767px) {
    .title {
        margin-bottom: 10px;
        font-size: 44px;
    }
}

@media screen and (min-width: 992px) {
    .title {
        font-size: 57px;
    }
}

.subtitle {
    margin: 0 auto;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    text-align: center;
    font-size: 14px;
    line-height: 23px;
    max-width: 266px;
    color: #008BFF;
}

@media screen and (min-width: 577px) {
    .subtitle {
        font-size: 16px;
        max-width: 300px;
    }
}

@media screen and (min-width: 767px) {
    .subtitle {
        font-size: 18px;
    }
}

@media screen and (min-width: 992px) {
    .subtitle {
        font-size: 20px;
        max-width: 500px;
    }
}


.drop_btn {
    position: relative;
    display: block;
    padding: 10px 35px;
    font-family: 'Tussilago', sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.04px;
    text-decoration: none;
    text-transform: uppercase;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    color: rgba(82, 114, 255, 1);
    -webkit-transition: all .45s;
    transition: all .45s;
}

@media screen and (min-width: 1400px) {
    .drop_btn {
        font-size: 14px;
        line-height: 15px;
    }
}

.drop_btn__circle {
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
}

.drop_btn__circle::after,
.drop_btn__circle::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 50%;
    height: 100%;
    border-radius: 0 100% 100% 0 / 0 50% 50% 0;
    background: rgba(82, 114, 255, 1);
    -webkit-transform-origin: center left;
            transform-origin: center left;
    -webkit-transition: all .45s;
    transition: all .45s;
    z-index: -1;
}

.drop_btn:hover {
    padding-left: 20px;
}

.drop_btn:hover .drop_btn__circle::before {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
}

.umbrella {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw !important;
    /*max-height: 60vh;*/
    height: auto;
    opacity: 0;
    -webkit-transition: all .5s, opacity 2s 4s;
    transition: all .5s, opacity 2s 4s;
}

@supports (-webkit-overflow-scrolling: touch) {
    .umbrella {
        height: 88vh;
    }
}

.umbrella .slick-slider {
    height: 100%;
}

.umbrella .slick-dots {
    bottom: 105px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    left: 70px;
}

.umbrella .slick-dots li {
    margin: 0 2px;
}

.umbrella .slick-dots li.slick-active button:before {
    color: rgba(255, 255, 255, 1);
}

.umbrella .slick-dots li button:before,
.umbrella .slick-dots li button:focus:before,
.umbrella .slick-dots li button:hover:before {
    opacity: 0.3;
    color: rgba(255, 255, 255, 1);
}

@media screen and (min-width: 680px) {
    .umbrella {
        /*width: 80%;*/
    }
}

@media screen and (min-width: 1024px) {
    .umbrella {
        /*top: 10%;*/
        /*max-width: 760px;*/
    }

    .umbrella .slick-dots {
        bottom: 235px;
    }
}

@media screen and (min-width: 1100px) {
    .umbrella {
        right: 40px;
        left: auto;
        /*width: 50%;*/
    }
}

@media screen and (min-width: 1200px) {
    .umbrella {
        /*top: 20%;*/
        max-height: none;
        /*max-width: 500px;*/
        -webkit-transform: translateX(0);
                transform: translateX(0);
        right: 0;
    }
}

@media screen and (min-width: 1400px) {
    .umbrella {
        /*max-width: 660px;*/
        top: 0;
        right: 0;
    }
}

.umbrella.active {
    opacity: 1;
}

.umbrella img {
    /*margin-left: -70px;*/
    max-height: 100vh;
    height: 90vh;
    -webkit-transform: translateX(-60%);
            transform: translateX(-60%);
    object-fit: cover;
    width: 250%;
}

@media screen and (min-width: 1200px) {
    .umbrella img {
        margin-left: 0;
        height: 100vh;
    }
}

@media screen and (min-width: 768px) {
    .umbrella img {
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
        width: 100%;
    }
}

.section-description {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 23px;
    color: rgba(0, 139, 255, 1);
}

@media screen and (min-width: 577px) {
    .section-description {
        font-size: 13px;
    }
}

@media screen and (min-width: 767px) {
    .section-description {
        font-size: 16px;
    }
}

.modalWrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all .45s;
    transition: all .45s;
}

.modalWrapper.show {
    opacity: 1;
    visibility: visible;
}

.slick-dots li button:before,
.slick-dots li button:focus:before,
.slick-dots li button:hover:before {
    opacity: 0.2;
    color: rgba(255, 255, 255, 1);
}

.slick-dots li.slick-active button:before {
    color: rgba(255, 255, 255, 1);
    opacity: 1;
}

@media screen and (min-width: 1200px) {
    .slick-dots li button:before,
    .slick-dots li button:focus:before,
    .slick-dots li button:hover:before {
        opacity: 0.2;
        color: rgba(0, 139, 255, 1);
    }

    .slick-dots li.slick-active button:before {
        color: rgba(0, 139, 255, 1);
        opacity: 1;
    }
}

.collection__top_slider .slick-dots li button:before,
.collection__top_slider .slick-dots li button:focus:before,
.collection__top_slider .slick-dots li button:hover:before {
    opacity: 0.2;
    color: rgba(0, 139, 255, 1);
}

.collection__top_slider .slick-dots li.slick-active button:before {
    color: rgba(0, 139, 255, 1);
    opacity: 1;
}

.commentsSection .slick-dots li button:before,
.commentsSection .slick-dots li button:focus:before,
.commentsSection .slick-dots li button:hover:before {
    opacity: 0.2;
    color: rgba(0, 139, 255, 1);
}

.commentsSection .slick-dots li.slick-active button:before {
    color: rgba(0, 139, 255, 1);
    opacity: 1;
}

/* ART LIST */
.artList__slider {
    justify-content: center;
}

.artList__slider * {
    outline: none;
}

.artList__slider .slick-track {
    /*padding: 12px 0 0;*/
}

ul.artList__slider .slick-track {
    display: flex;
    /*justify-content: center;*/
}

ul.artList__slider .slick-slide {
    position: relative;
}

ul.artList__slider li.active span,
ul.artList__slider li:hover span {
    -webkit-transform: translateY(-12px);
            transform: translateY(-12px);
}

ul.artList__slider li {
    position: relative;
    width: 80px !important;
    -webkit-transition: all .45s;
    transition: all .45s;
    border-top: 1px solid rgba(234, 234, 234, 1);
    border-left: 1px solid rgba(234, 234, 234, 1);
    margin-right: 6px;
}

@media screen and (min-width: 1200px) {
    ul.artList__slider li {
        width: 100px !important;
        margin-right: 0;
    }
}

@media screen and (min-width: 1400px) {
    ul.artList__slider li {
        width: 155px !important;

    }
}

ul.artList__slider li::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 0;
    height: 1px;
    background-color: #5272FF;
    -webkit-transition: all .45s;
    transition: all .45s;
}

ul.artList__slider li.active::after {
    width: 100%;
}

ul.artList__slider li img {
    width: 56px;
    height: auto;
}

@media screen and (min-width: 1200px) {
    ul.artList__slider li img {
        width: 100%;
    }
}

.artList__slider .slick-slider {
    width: 100%;
}

.slick-slider * {
    outline: none;
}

/* Comments Section */
.commentsSection .slick-track {
    display: flex;
}

.commentsSection .slick-dots {
    bottom: -35px;
}

.logo {
    position: absolute;
    right: auto;
    left: -20px;
    top: 35%;
    display: flex;
    justify-content: flex-end;
    padding: 0;
    width: 40px;
    height: 78px;
    border: none;
    cursor: pointer;
    background: transparent;
    z-index: 3;
    -webkit-transition: all .15s;
    transition: all .15s;
    outline: none;
}

.logo span {
    display: block;
    width: 20px;
    height: 78px;
    pointer-events: none;
}

@media screen and (min-width: 1200px) {
    .logo {
        position: fixed;
        right: -18px;
        left: auto;
    }

    .logo:hover {
        right: -10px;
    }

    .logo.up {
        right: -50px;
    }

    .logo span {
        background-color: #FFCD00;
    }
}

.logo::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 20px;
    height: 80px;
    border: none;
    background-color: transparent;
    background-image: url(/static/media/zontjk_logo_bot_small.38c4dff4.svg);
    background-repeat: no-repeat;
    background-position: top;
    background-size: contain;
    cursor: pointer;
    z-index: -1;
    pointer-events: none;
}

.logo_top__wrapper {
    position: fixed;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    padding: 0 24px;
    max-width: 970px;
    width: 100%;
    z-index: 3;
}

@media screen and (min-width: 1200px) {
    .logo_top__wrapper {
        padding-left: 0;
        padding-right: 0;
    }
}

.logo_top {
    position: absolute;
    top: -10px;
    display: block;
    padding: 0;
    width: 74px;
    height: 10px;
    border: none;
    background-color: #FFCD00;
    cursor: pointer;
    -webkit-transition: all .15s;
    transition: all .15s;
    outline: none;
}

@media screen and (min-width: 1200px) {
    .logo_top:hover {
        top: -5px;
    }

    .logo_top.up {
        top: -48px;
    }
}

.logo_top::before {
    content: '';
    position: absolute;
    top: 100%;
    left: -10px;
    display: block;
    width: 20px;
    height: 80px;
    background-image: url(/static/media/zontjk_logo_bot_big.89a392f1.svg);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: contain;
    -webkit-transform: rotate(-90deg) translateX(-10px);
            transform: rotate(-90deg) translateX(-10px);
    -webkit-transform-origin: top;
            transform-origin: top;
}

/**
Preloader
 */
@media (max-width: 576px) {

    #preloader {
        -webkit-transform: translateY(-75px);
                transform: translateY(-75px);
    }
}

div.my-preloader div {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    border: none;
    background: #5272FF;
}

/**
Animation
*/
.animate_to_top {
    -webkit-transform: translateY(300%);
            transform: translateY(300%);
    opacity: 0;
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
}

.animate_to_top.animate {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    opacity: 1;
}

/**
Stores
 */
@media screen and (max-width: 1199px) {
    .stores__list_parallax .parallax-inner {
        -webkit-transform: none !important;
        transform: none !important;
    }
}

.Typist {
    position: relative;
}

.Cursor--blinking {
    position: absolute;
    right: -10px;
    bottom: 0;
    margin-bottom: 0 !important;
    -webkit-animation: animateBlinkibg 0.5s linear alternate infinite;
            animation: animateBlinkibg 0.5s linear alternate infinite;
}

@-webkit-keyframes animateBlinkibg {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes animateBlinkibg {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.aboutPage ~ #wheretobuy {
    background: #FFFFFF;
}

.collection__top_slider {
    z-index: 0;
}

.collection__top_slider .slick-list {
    overflow: hidden;
}

.collection__top_slider .slick-list:hover {
    overflow: visible;
}

.collection__top_slider .slick-track {
    pointer-events: none;
}

.collection__top_slider .slick-slide {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all .45s;
    transition: all .45s;
}

.collection__top_slider .slick-current {
    opacity: 1;
    visibility: visible;
}

.collection__top_slider .slick-slide * {
    cursor: none !important;
}

.collection__top_slider .slick-slide {
    pointer-events: all;
}

.collection__top_slider .slick-current .zoomImageRoot div {
    background: rgba(255, 255, 255, 0.4) !important;
}

.modalSlider {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.8);
    opacity: 0;
    visibility: hidden;
    z-index: 5;
    -webkit-transition: all .45s;
    transition: all .45s;
}

.modalSlider.show {
    opacity: 1;
    visibility: visible;
}

.modalSlider img {
    margin: 0 auto;
    width: auto;
    max-height: 80vh;
}

.modalSlider__big {
    margin: 0 auto;
    margin-bottom: 30px;
    width: 50vw;
    max-height: 80vh;
}

.modalSlider__big .slick-slide {
    opacity: 0;
    pointer-events: none;
    cursor: default;
    -webkit-transition: all .45s;
    transition: all .45s;
}

.modalSlider .slick-current {
    pointer-events: all;
    cursor: pointer;
    opacity: 1;
}

.modalSlider__small ul li span {
    width: 95px;
    height: 95px;
    background: rgba(255, 255, 255, 0.5);
}

.modalSlider__small ul.artList__slider li {
    width: 95px !important;
}

.modalSlider__small span:hover,
.modalSlider__small .slick-current span {
    background: rgba(255, 255, 255, 0.8);
}

@media screen and (min-width: 1200px) {
    .largeImage {
        left: -100% !important;
        width: 400px !important;
        z-index: 3;
    }
}

.mainSlider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.mainSlider .slick-slider,
.mainSlider .slick-list {
    height: 100%;
}

.mainSlider .slick-slide {
    position: relative;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all .45s;
    transition: all .45s;
}

.mainSlider .slick-current {
    opacity: 1;
    visibility: visible;
}

.umbrella__wrapper {
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
    background-size: cover;
    background-image: url(/static/media/landbg.44d952ff.jpg);
}

.umbrella__wrapper.isBackToHome {
    background: #f7f7f7;
}

@media screen and (min-width: 1600px) {
    .umbrella__wrapper {
        position: relative;
        left: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
        width: 100vw !important;
    }

    .umbrella__container {
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        /*max-width: 1280px;*/
    }
}

#preloader {
    -webkit-transition: all 0.5s ease-in;
    transition: all 0.5s ease-in;
}

.mainSlider .slick-dots {
    bottom: 100px;
    left: 0;
    max-width: -webkit-max-content;
    max-width: -moz-max-content;
    max-width: max-content;
    z-index: 2;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                     supported by Chrome and Opera */
}

.homePage {
    opacity: 0;
    -webkit-transition: all 2s;
    transition: all 2s;
}

.homePage.isBackToHome + #wheretobuy {
    opacity: 0;
}

.homePage.mounted {
    opacity: 1;
}

.homePage.unmounted .storySection__info {
    opacity: 0;
}

.homePage.mounted .storySection__wrapper::before {
    left: -40%;
}

/*.homePage.mounted .storySection__wrapper.animateZlata::before {*/
/*    left: -51%;*/
/*}*/

.homePage.mounted .storySection__wrapper.animateZlata,
.homePage.unmounted .storySection__wrapper::before {
    opacity: 0.4;
}

.homePage.mounted .storySection__wrapper::before {
    opacity: 1;
}

.aboutPage.unmounted * {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 1s;
    transition: all 1s;
    /*height: 0;*/
}

.aboutPage.unmounted::before {
    opacity: 0.4;
    height: 800px;
    visibility: visible;
}

div.unmounted {
    order: -1;
}

.aboutPage + section .container {
    margin: 0 auto;
    padding: 0 24px;
    max-width: 970px;
    width: 100%;
}

@media screen and (min-width: 1400px) {
    .homePage.mounted .storySection__wrapper.animateZlata::before {
        left: -57%;
    }
}

@media screen and (min-width: 1600px) {
    .homePage.mounted .storySection__wrapper.animateZlata::before {
        left: -75%;
    }
}

.Home_home__2oa-D {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100vh;
    color: #ffffff;
    overflow: hidden;
    -webkit-overflow-scrolling: auto;
}

@supports (-webkit-overflow-scrolling: touch) {
   .Home_home__2oa-D {
       height: 88vh;
   }
}

.Home_home__2oa-D::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    /*background-image: url('../../assets/img/bgblue_top.jpg');*/
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    -webkit-appearance: none;
    width: 100vw;
    height: 100vh;
    z-index: 0;
    -webkit-transition: box-shadow .45s .4s;
    transition: box-shadow .45s .4s;
}

.Home_home__2oa-D.Home_showUmbrella__37zw4 {
    overflow: visible;
}

.Home_home__2oa-D header {
    opacity: 0;
    -webkit-transition: opacity 1s 2s;
    transition: opacity 1s 2s;
}

.Home_home__2oa-D.Home_loadedPage__3gCTF header {
    opacity: 1;
}

.Home_home__title__28htI,
.Home_home__btn__1Osh_ {
    opacity: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    -webkit-transition: all .45s 2.2s;
    transition: all .45s 2.2s;
}

.Home_home__title__28htI {
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transform: translate(-20%,-100px) scale(1.5);
            transform: translate(-20%,-100px) scale(1.5);
    text-align: right;
    font-family: 'Tussilago', sans-serif;
    font-weight: bold;
    font-size: 15px;
    line-height: 15px;
    /*max-width: 80%;*/
    color: #F9F9F9;
    z-index: 2;
    text-transform: uppercase;
    max-width: 60%;
}

.Home_home__title__28htI span {
    -webkit-transition: all 0s;
    transition: all 0s;
}

.Home_home__2oa-D .Home_home__title__28htI.Home_delete_storke__1dhAe {
    -webkit-text-fill-color: #fff;
    background-color: transparent;
    -webkit-transition: all 1s, background-color 0s;
    transition: all 1s, background-color 0s;
    -webkit-text-stroke-width: 0;
}

@media screen and (min-width: 992px) {
    .Home_home__title__28htI {
        max-width: 500px;
    }
}

.Home_home__subtitle__3iUc5 {
    position: relative;
    margin-top: auto;
    width: 100%;
    max-width: 80%;
    font-family: 'Tussilago', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: right;
    color: rgba(255,255,255,1);
    z-index: 2;
}

.Home_home__subtitle_item__2Z2aB {
    display: block;
    margin-bottom: 15px;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 14px;
    height: 25px;
    overflow: hidden;
    -webkit-transition: all .25s ease-in, background 0s;
    transition: all .25s ease-in, background 0s;
}

.Home_home__subtitle_item__2Z2aB.Home_delete_storke__1dhAe,
.Home_home__subtitle_item__2Z2aB.Home_delete_storke__1dhAe span {
    -webkit-transition: all 1s ease 0s;
    transition: all 1s ease 0s;
    overflow: visible;
}

@media screen and (min-width: 1024px) {
    .Home_home__subtitle__3iUc5 {
        margin-bottom: 50px;
    }
}

@media screen and (min-width: 1100px) {
    .Home_home__subtitle__3iUc5 {
        max-width: 50%;
    }
}

@media screen and (min-width: 1200px) {
    .Home_home__subtitle_wrapper__IvYuK {
        height: 25px;
        margin-bottom: 25px;
    }

    .Home_home__subtitle_wrapper__IvYuK.Home_active__14uGB {
        height: 0;
    }

    .Home_home__subtitle_item__2Z2aB {
        margin-bottom: 0;
    }
}

@media screen and (min-width: 1400px) {
    .Home_home__subtitle__3iUc5 {
        margin-bottom: 100px;
    }
}

@media screen and (min-width: 1600px) {
    .Home_home__subtitle__3iUc5 {
        margin-bottom: 150px;
    }
}

.Home_home__subtitle_item__2Z2aB span {
    display: block;
    -webkit-transform: translateY(40px);
            transform: translateY(40px);
    -webkit-transition: all 1s ease-in 3s;
    transition: all 1s ease-in 3s;
}

.Home_home__subtitle_item__2Z2aB:nth-of-type(2) span {
    -webkit-transition-delay: 2.8s;
            transition-delay: 2.8s;
}

.Home_home__subtitle_item__2Z2aB:nth-of-type(3) span {
    -webkit-transition-delay: 3s;
            transition-delay: 3s;
}

.Home_home__subtitle_item__2Z2aB:nth-of-type(4) span {
    -webkit-transition-delay: 3.2s;
            transition-delay: 3.2s;
}

.Home_home__subtitle_item__2Z2aB.Home_delete_storke__1dhAe span {
    -webkit-transition: all 0s;
    transition: all 0s;
    -webkit-transition-delay: 0s;
            transition-delay: 0s;
}

@media screen and (min-width: 577px) {
    .Home_home__subtitle_item__2Z2aB {
        font-size: 14px;
        line-height: 16px;
    }
}

@media screen and (min-width: 1024px) {
    .Home_home__subtitle_item__2Z2aB {
        margin-bottom: 30px;
    }
}

@media screen and (min-width: 1400px) {
    .Home_home__subtitle_item__2Z2aB {
        font-size: 15px;
        line-height: 17px;
    }
}

.Home_home__btn_wrapper__Urdru {
    display: flex;
    flex-direction: column-reverse;
    margin: 0;
    margin-top: 5px;
    padding: 0;
    z-index: 2;
}

@media screen and (min-width: 767px) {
    .Home_home__btn_wrapper__Urdru {
        margin-top: 100px;
    }
}

@media screen and (min-width: 1200px) {
    .Home_home__btn_wrapper__Urdru {
        margin-top: 0px;
    }
}

@media screen and (min-width: 1400px) {
    .Home_home__btn_wrapper__Urdru {
        margin-top: 100px;
    }
}

.Home_home__btn__1Osh_ {
    position: relative;
    margin-bottom: -2px;
    padding: 30px;
    font-family: 'Tussilago', sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: -0.04px;
    text-transform: uppercase;
    text-align: left;
    border-radius: 0;
    border: none;
    width: 100%;
    color: #5272FF;
    background: rgba(255,255,255,1);
    -webkit-transition-delay: 3s;
            transition-delay: 3s;
    cursor: pointer;
    outline: none;
    z-index: 2;
}

.Home_home__btn__1Osh_::after {
    content: '';
    position: absolute;
    right: 30px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 20px;
    height: 20px;
    -webkit-animation: Home_bounce__2_TkW 3s infinite;
    animation: Home_bounce__2_TkW 3s infinite;
    background-image: url(/static/media/arrow.02f441c1.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    -webkit-transition: all .45s;
    transition: all .45s;
}

/*.home__btn:hover::after {*/
/*    */
/*}*/
@-webkit-keyframes Home_bounce__2_TkW {
    0%, 20%, 50%, 80%, 100% {
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }
    40% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }
    60% {
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
    }
}
@keyframes Home_bounce__2_TkW {
    0%, 20%, 50%, 80%, 100% {
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }
    40% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }
    60% {
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
    }
}

@media screen and (min-width: 1200px) {
    .Home_home__btn_wrapper__Urdru {
        flex-direction: row;
        align-items: center;
        padding: 0 24px;
    }

    .Home_home__btn__1Osh_ {
        padding: 30px 40px;
        width: 50%;
        height: 100%;
    }

    .Home_home__btn__1Osh_:hover {
        background-color: rgba(255,255,255,0.9);
    }

    .Home_home__btn__1Osh_.Home_deleteDelay__1knqk {
        -webkit-transition-delay: 0s;
                transition-delay: 0s;
    }
}

@media screen and (min-width: 1400px) {
    .Home_home__btn_wrapper__Urdru {
        margin-left: auto;
        margin-right: auto;
        margin-top: 0;
    }

    .Home_home__btn__1Osh_ {
        padding: 50px 60px;
    }
}

/*.home.loadedPage .home__title,*/
.Home_home__2oa-D.Home_loadedPage__3gCTF .Home_home__btn__1Osh_ {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
}

.Home_home__2oa-D.Home_loadedPage__3gCTF .Home_home__title__28htI {
    opacity: 1;
}

.Home_home__2oa-D.Home_loadedPage__3gCTF .Home_home__subtitle_item__2Z2aB span {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
}

/*.home__btn:hover::after {*/
/*    top: 60%;*/
/*}*/

.Home_sliderBtns__wrapper__2kH0a {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity .45s 3s;
    transition: opacity .45s 3s;
}

.Home_sliderBtns__wrapper__2kH0a.Home_show__2Xchm {
    opacity: 1;
    visibility: visible;
}

@media screen and (min-width: 1200px) {
    .Home_sliderBtns__wrapper__2kH0a {
        display: flex;
        align-items: center;
        flex: 1 1;
        padding-right: 40px;
        height: 100%;
        background-color: rgba(255,255,255,0.1);
    }
}

.Home_sliderBtns__1mnhk {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    max-width: calc(80% + 20px);
    width: 100%;
    z-index: 2;
}

.Home_sliderBtns__arrow__mMMTa {
    padding: 30px 20px;
    opacity: 0.3;
    -webkit-transition: all .45s;
    transition: all .45s;
    cursor: pointer;
}

.Home_sliderBtns__arrow__mMMTa:hover {
    opacity: 1;
}

.Home_sliderBtns__arrow__mMMTa:active {
    opacity: 0.3;
}

.Home_sliderCounter__FQX1J {
    margin-left: 60px;
}

@media screen and (min-width: 1200px) {
    .Home_sliderCounter__FQX1J {
        display: flex;
    }
}

.Home_sliderCounter__FQX1J span {
    font-family: 'Syncopate', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #FFFFFF;
}

span.Home_sliderCounter__current__Ccf09 {
    font-weight: 700;
}

.Home_sliderCounter__sepo__1fbdM {
    display: inline-block;
    margin: 0 10px;
}

@media screen and (min-width: 767px) {
    .Home_home__title__28htI {
        -webkit-transform: translate(-50%,-100px) scale(3);
                transform: translate(-50%,-100px) scale(3);
        max-width: none;
    }
}

@media screen and (min-width: 1023px) {
    .Home_home__title__28htI {
        -webkit-transform: translate(-70%,-100px) scale(4);
                transform: translate(-70%,-100px) scale(4);
    }
}

@media screen and (min-width: 1200px) {
    .Home_home__title__28htI {
        left: 50%;
        -webkit-transform: translate(-50%,-150px) scale(3.5);
                transform: translate(-50%,-150px) scale(3.5);
        max-width: none;
        background-color: transparent;
        background-size: auto;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-position: 0;
        background-size: 200%;
        -webkit-text-stroke-color: #ffffff;
        -webkit-text-stroke-width: 0.2px;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        background-clip: text;
        overflow: hidden;
        background-color: transparent;
        -webkit-transition: all 2s 2s, background 1s 2.4s, background-position 4s 2.4s;
        transition: all 2s 2s, background 1s 2.4s, background-position 4s 2.4s;
        /*background-image: url('../../assets/img/text-bg.png');*/
        /*background-repeat: no-repeat;*/
        /*background-position: center;*/
        /*background-position: 0px 130%;*/
        /*background-size: 100%;*/
    }

    /*.home__title::before {*/
    /*    position: absolute;*/
    /*    content: 'Real art umbrellas';*/
    /*}*/

    .Home_home__2oa-D.Home_loadedPage__3gCTF .Home_home__title__28htI {
        /*-webkit-text-stroke-color: transparent;*/
        /*background-color: #fff;*/
        /*background-position: 0px 50%;*/
        background-color: #fff;
    }

    .Home_home__2oa-D .Home_home__title__28htI.Home_delete_storke__1dhAe {
        /*-webkit-text-fill-color: transparent;*/
        background-color: transparent;
        -webkit-transition: all 1s, background-color 0s;
        transition: all 1s, background-color 0s;
        -webkit-text-stroke-width: 0;
    }
}

@media screen and (min-width: 1400px) {
    .Home_home__title__28htI {
        -webkit-transform: translate(-10%,-150px) scale(5);
                transform: translate(-10%,-150px) scale(5);
    }
}

.Home_home__info__1NHHG {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    padding-top: 10vh;
}

@media screen and (min-width: 1600px) {
    .Home_home__info__1NHHG {
        position: static;
        margin: 0 auto;
        max-width: 1280px;
        width: 100%;
    }
}

.Home_homeLogo__container__1dPJi {
    display: block;
}

@media screen and (min-width: 1200px) {
    .Home_homeLogo__container__1dPJi {
        position: relative;
        display: none;
    }
}

.Header_header__2UmEN {
    position: fixed;
    top: 0;
    left: 0;
    padding: 27px 0;
    width: 100vw;
    z-index: 3;
}
.Header_header__color__2s6c4 {
    background-color: #3061A1;
    z-index: 4;

}
@media screen and (min-width: 1200px){
    .Header_header__2UmEN {
        padding: 24px 0;
        background: transparent;
    }
    .Header_header__color__2s6c4 {
        background-color: transparent;
    }
}

.Header_header__container__1OeXy {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

@media screen and (min-width: 1600px) {
    .Header_header__container__1OeXy {
        position: relative;
    }
}

.Header_burger__wrapper__g4xVY {
    position: fixed;
    top: -20px;
    right: -10px;
    width: 100px;
    height: 100px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
    -webkit-transition: all .25s;
    transition: all .25s;
}

@media screen and (min-width: 1200px) {
    .Header_burger__wrapper__g4xVY {
        top: -35px;
        right: -30px;
        width: 150px;
        height: 150px;
        opacity: 0;
        visibility: hidden;
    }

    .Header_burger__wrapper__g4xVY.Header_show__3NdXu {
        opacity: 1;
        visibility: visible;
    }
}

@media screen and (min-width: 1400px) {
    .Header_burger__wrapper__g4xVY {
        right: 40px;
    }
}

.Header_burger__3ka4j {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    -webkit-transform-origin: center;
            transform-origin: center;
    cursor: pointer;
}

/*.burger.active {*/
/*    background-color: rgba(82,114,255,1);*/
/*}*/

.Header_burger__circle__34pba::after,
.Header_burger__circle__34pba::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 50%;
    height: 100%;
    border-radius: 0 100% 100% 0 / 0 50% 50% 0;
    background: rgba(82,114,255,1);
    -webkit-transition: all .45s;
    transition: all .45s;
    z-index: -1;
}

.Header_burger__circle__34pba::before {
    -webkit-transform-origin: 2% 50%;
    transform-origin: 2% 50%;
}

.Header_burger__3ka4j.Header_active__2f1cs .Header_burger__circle__34pba::before {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

/*.burger.active .burger__circle {*/
/*    background: rgba(82,114,255,1);*/
/*}*/

@media screen and (min-width: 1200px) {
    .Header_burger__wrapper__g4xVY:hover .Header_burger__title__30C-J {
        -webkit-transform: translateY(-50%) translateX(5px);
                transform: translateY(-50%) translateX(5px);
    }
}

@media screen and (min-width: 1600px) {
    .Header_burger__wrapper__g4xVY {
        position: absolute;
        right: -20px;
        top: -60px;
    }
}

.Header_burger__3ka4j.Header_change_color__1tvRf .Header_burger__title__30C-J::before,
.Header_burger__3ka4j.Header_change_color__1tvRf .Header_burger__title__30C-J::after {
    color: rgba(82,114,255,1);
}

/*.burger::before,*/
.Header_burger__circle__34pba {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    /*right: 0;*/
    display: block;
    /*width: 50%;*/
    width: 100%;
    height: 100%;
    border-radius: 50%;
    /*border-radius: 0 100% 100% 0 / 0 50% 50% 0;*/
    /*background: rgba(82,114,255,1);*/
    /*border: 1px solid rgba(82,114,255,1);*/
    -webkit-transform-origin: center left;
            transform-origin: center left;
    -webkit-transition: width .45s, height .45s, border-width .45s;
    transition: width .45s, height .45s, border-width .45s;
    z-index: -1;
}

@media screen and (min-width: 1200px) {
    .Header_burger__wrapper__g4xVY:hover .Header_burger__circle__34pba {
        border-width: 2px;
        width: 150%;
        height: 150%;
    }

    .Header_burger__wrapper__g4xVY:active .Header_burger__circle__34pba,
    .Header_burger__3ka4j.Header_active__2f1cs .Header_burger__circle__34pba {
        border-width: 1px;
        width: 100%;
        height: 100%;
    }
}

.Header_burger__bar_wrapper__2uTl5 {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.Header_burger__title__30C-J {
    position: absolute;
    left: -170%;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    font-family: 'Syncopate', sans-serif;
    font-weight: 700;
    font-size: 11px;
    line-height: 11px;
    color: transparent;
    overflow: hidden;
    -webkit-transition: all .45s;
    transition: all .45s;
}

.Header_burger__3ka4j.Header_active__2f1cs .Header_burger__title__30C-J::before,
.Header_burger__3ka4j.Header_active__2f1cs .Header_burger__title__30C-J::after {
    color: #ffffff;
}

.Header_burger__title__30C-J::before,
.Header_burger__title__30C-J::after {
    content: 'menu';
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    font-family: 'Syncopate', sans-serif;
    font-weight: 700;
    font-size: 11px;
    line-height: 11px;
    color: #ffffff;
    -webkit-transition: all .45s;
    transition: all .45s;
}

.Header_burger__3ka4j.Header_active__2f1cs .Header_burger__title__30C-J::before {
    -webkit-transform: translate(-50%,100%);
            transform: translate(-50%,100%);
}

.Header_burger__title__30C-J::after {
    content: 'close';
    -webkit-transform: translate(-50%,100%);
            transform: translate(-50%,100%);
}

.Header_burger__3ka4j.Header_active__2f1cs .Header_burger__title__30C-J::after {
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
}

.Header_burger__bar__3ny9k {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    display: block;
    width: 11px;
    height: 3px;
    background-color: #E3E3E3;
    -webkit-transition: all .45s;
    transition: all .45s;
}

/*.burger.change_color .burger__bar {*/
/*    background-color: rgba(82,114,255,1);*/
/*}*/

.Header_burger__3ka4j.Header_change_color__1tvRf.Header_active__2f1cs .Header_burger__bar__3ny9k {
    background-color: #E3E3E3;
}

.Header_burger__3ka4j.Header_active__2f1cs .Header_burger__bar__3ny9k {
    -webkit-transform: translate(-50%,-50%) rotate(45deg);
            transform: translate(-50%,-50%) rotate(45deg);
}

.Header_burger__bar__3ny9k:nth-child(2) {
    -webkit-transform: translate(-50%,-50%) rotate(-90deg);
            transform: translate(-50%,-50%) rotate(-90deg);
}

.Header_burger__3ka4j.Header_active__2f1cs .Header_burger__bar__3ny9k:nth-child(2) {
    -webkit-transform: translate(-50%,-50%) rotate(-45deg);
            transform: translate(-50%,-50%) rotate(-45deg);
}

@media screen and (min-width: 1200px) {
    .Header_burger__title__30C-J {
        left: -200%;
    }
}

/* COOKIES */
.Header_cookies__e_ZCc {
    position: fixed;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    bottom: 0;
    left: 0;
    padding: 10px 15px 10px 15px;
    background: -webkit-radial-gradient(circle, rgba(88,90,156,1) 0%, rgba(38,40,90,1) 100%);
    background: radial-gradient(circle, rgba(88,90,156,1) 0%, rgba(38,40,90,1) 100%);
    -webkit-transform: translateY(200%);
            transform: translateY(200%);
    opacity: 0;
    -webkit-transition: all .45s 6s;
    transition: all .45s 6s;
    z-index: 10;
}

.Header_cookies__e_ZCc.Header_hide__zIGo5 {
    opacity: 0;
    visibility: hidden;
}

.Header_cookies__e_ZCc.Header_pageLoaded__1RUbW {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
}

.Header_cookies__e_ZCc.Header_change_color__1tvRf {
    background: -webkit-radial-gradient(circle, rgba(88,90,156,1) 0%, rgba(38,40,90,1) 100%);
    background: radial-gradient(circle, rgba(88,90,156,1) 0%, rgba(38,40,90,1) 100%);
}

.Header_cookies__text__28HEl {
    flex: 1 1;
    margin-bottom: 10px;
}

.Header_cookies__text__28HEl p {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.04px;
    color: #FFFFFF;
}

.Header_cookies__btn__2ppeC {
    padding: 10px;
    font-family: 'Syncopate', sans-serif;
    font-weight: 400;
    font-size: 10px;
    line-height: 11px;
    letter-spacing: -0.03px;
    text-transform: uppercase;
    border-radius: 12px;
    outline: none;
    border: none;
    width: 100%;
    color: #1E1F3F;
    background-color: #FFFFFF;
    cursor: pointer;
    -webkit-transition: all .45s;
    transition: all .45s;
}

.Header_cookies__btn__2ppeC:hover {
    box-shadow: 0 0 10px 10px rgba(255,255,255,0.1);
}

.Header_cookies__btn__2ppeC:active {
    box-shadow: none;
}

@media screen and (min-width: 1200px) {
    .Header_cookies__e_ZCc {
        width: 330px;
        background-color: rgba(255,255,255,1);
    }
}

.MobileMenu_mobile_menu__3LJ2F {
    position: fixed;
    top: 0;
    right: 0;
    display: block;
    padding-top: 90px;
    width: 80vw;
    height: 100vh;
    background-color: #1D1E44;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    -webkit-transition: all .45s;
    transition: all .45s;
    z-index: 4;
}

.MobileMenu_mobile_menu__3LJ2F.MobileMenu_color__HEGNV {
    background-color: #3061A1;
    height: 205px;
    padding-bottom: 62px;
}
.MobileMenu_mobile_menu__3LJ2F.MobileMenu_color__HEGNV .MobileMenu_mobile_menu__container__3uq0E{
    height: 100%;
}
@media screen and (max-width: 370px) {
    .MobileMenu_mobile_menu__3LJ2F {
        padding-top: 100px;
    }
}

@media screen and (max-width: 575px) and (min-height: 670px) {
    .MobileMenu_mobile_menu__3LJ2F {
        padding-top: 110px;
    }
}

@media screen and (min-width: 767px) {
    .MobileMenu_mobile_menu__3LJ2F {
        padding-top: 200px;
    }
}

@media screen and (min-width: 1200px) {
    .MobileMenu_mobile_menu__3LJ2F {
        opacity: 1;
        visibility: visible;
        padding-top: 0;
        height: auto;
        width: 100vw;
        background: transparent;
        -webkit-transform: translateY(-100%);
                transform: translateY(-100%);
    }
}

.MobileMenu_mobile_menu__3LJ2F.MobileMenu_active__Zwgty {
    -webkit-transform: translateX(0);
            transform: translateX(0);
}

@media screen and (min-width: 1200px) {
    .MobileMenu_mobile_menu__3LJ2F.MobileMenu_active__Zwgty {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }

    .MobileMenu_mobile_menu__3LJ2F.MobileMenu_scrolled__1TXIW {
        background: rgba(82, 114, 255, 1);
        z-index: 4;
    }
}

.MobileMenu_mobile_menu__main__1afVy {
    margin-bottom: 120px;
}

@media screen and (max-width: 575px) and (min-height: 670px) {
    .MobileMenu_mobile_menu__main__1afVy {
        margin-bottom: 160px;
    }
}

@media screen and (min-width: 1200px) {
    .MobileMenu_mobile_menu__main__1afVy {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 0;
    }

    .MobileMenu_mobile_menu__main__1afVy.MobileMenu_onTop__3kuKr li:last-child {
        margin-right: 0;
    }
}

.MobileMenu_mobile_menu__main__1afVy li {
    cursor: pointer;
}

@media screen and (min-width: 1200px) {
    .MobileMenu_mobile_menu__main__1afVy li {
        position: relative;
        margin-right: 30px;
        -webkit-transition: all .25s;
        transition: all .25s;
    }

    .MobileMenu_mobile_menu__main__1afVy li::before {
        content: '';
        position: absolute;
        bottom: 25px;
        left: 0;
        display: block;
        width: 0;
        height: 1px;
        background-color: #FFCD00;
        -webkit-transition: all .45s;
        transition: all .45s;
    }

    .MobileMenu_mobile_menu__main__1afVy li:last-child {
        margin-right: 230px;
    }

    .MobileMenu_mobile_menu__main__1afVy li:not(:last-child) {
        margin-bottom: 0;
    }
}

.MobileMenu_mobile_menu__main__1afVy a {
    display: block;
    padding: 15px 0;
    font-family: 'Tussilago', sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 15px;
    text-decoration: none;
    text-align: right;
    letter-spacing: -0.04px;
    color: #E3E3E3;
    -webkit-transition: all .45s;
    transition: all .45s;
}

@media screen and (min-width: 992px) {
    .MobileMenu_mobile_menu__main__1afVy a {
        font-size: 28px;
        line-height: 34px;
    }
}

@media screen and (min-width: 1200px) {
    .MobileMenu_mobile_menu__main__1afVy a {
        padding: 33px 0;
        font-size: 12px;
        line-height: 12px;
        color: #E3E3E3;
        /*opacity: 0.5;*/
    }

    .MobileMenu_mobile_menu__main__1afVy li:hover::before,
    .MobileMenu_mobile_menu__main__1afVy li.MobileMenu_active__Zwgty::before {
        width: 100%;
    }
}

.MobileMenu_mobile_menu__main__1afVy li span {
    -webkit-transition: all .45s;
    transition: all .45s;
}

@media screen and (min-width: 1200px) {
    .MobileMenu_mobile_menu__main__1afVy li:hover span,
    .MobileMenu_mobile_menu__main__1afVy li.MobileMenu_active__Zwgty span {
        display: block;
        opacity: 1;
        /*transform: translateY(-3px);*/
    }
}

.MobileMenu_mobile_menu__secondary__1o22J a {
    display: block;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 18px;
    line-height: 36px;
    text-decoration: none;
    text-align: left;
    color: #ffffff;
}

.MobileMenu_mobile_menu__main__1afVy li.MobileMenu_active__Zwgty a,
.MobileMenu_mobile_menu__main__1afVy li:hover a {
    opacity: 1;
}

@media screen and (min-width: 1200px) {
    .MobileMenu_mobile_menu__secondary__1o22J {
        display: none;
    }
}

.MobileMenu_mobile_menu__socials__TrG-L {
    margin-top: 70px;
}

@media screen and (min-width: 1200px) {
    .MobileMenu_mobile_menu__socials__TrG-L {
        display: none;
    }
}

.MobileMenu_mobile_menu__container__3uq0E {
    display: flex;
    align-items: center;
    justify-content: space-between;
}




.Socials_socials__3Xo6R {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
}

.Socials_socials__item__R4cVC {
    position: relative;
    margin-bottom: 30px;
    width: 23px;
    height: 23px;
    border-radius: 50%;
    opacity: 0.34;
    -webkit-transition: all .45s;
    transition: all .45s;
}

.Socials_socials__item__R4cVC:last-child {
    margin-right: 0;
}

.Socials_socials__item__R4cVC:hover {
    opacity: 1;
}

.Socials_socials__item__R4cVC a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.Socials_socials__item__R4cVC img {
    max-width: 100%;
}
.Collection_collection__3I-CK {
    background: rgb(242, 242, 246);
}

@media screen and (min-width: 1200px) {
    .Collection_collection__3I-CK {
        position: relative;
        background-color: #F7F7F7;
    }
}

@media screen and (min-width: 1400px) {
    .Collection_collection__3I-CK {
        background-color: transparent;
    }

    .Collection_collection__3I-CK::before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
        display: block;
        width: 100vw;
        height: 100vh;
        background-image: url(/static/media/top_bg.d71527d0.png);
        background-repeat: no-repeat;
        background-position: top center;
        background-size: cover;
        z-index: -1;

    }
}

.Collection_collection__dark__14AG6 {
    padding-left: 0;
}

@media screen and (min-width: 1200px) {
    .Collection_collection__light__LcPjp {
        position: relative;
    }
}

@media screen and (min-width: 1400px) {
    .Collection_collection__dark__14AG6 {
        padding-top: 70px;
    }
}

.Collection_zoom__ajuLf {
    position: absolute;
    top: 75px;
    right: 85px;
    width: 46px;
    height: 46px;
    background-image: url(/static/media/cursor_zoom.1625e170.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    -webkit-transition: all .45s;
    transition: all .45s;
    cursor: pointer;
    opacity: 0.8;
}

@media screen and (min-width: 577px) {
    .Collection_zoom__ajuLf {
        top: 95px;
        right: 115px;
    }
}

@media screen and (min-width: 768px) {
    .Collection_zoom__ajuLf {
        top: 125px;
        right: 185px;
    }
}

@media screen and (min-width: 1200px) {
    .Collection_zoom__ajuLf {
        right: 105px;
        -webkit-transition-duration: 0s;
                transition-duration: 0s;
        -webkit-transition-delay: 0s;
                transition-delay: 0s;
        cursor: none;
        pointer-events: none;
    }
}

.Collection_zoom__ajuLf:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}

.Collection_zoom__ajuLf:active {
    -webkit-transform: scale(1);
            transform: scale(1);
}

.Collection_zoom__img_wrapper__1Jw_8 {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 5;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all .45s;
    transition: all .45s;
}

.Collection_zoom__img_wrapper__1Jw_8.Collection_active__29p6R {
    opacity: 1;
    visibility: visible;
}

.Collection_zoom__img__Xq4XG {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
    -webkit-transition: all .45s;
    transition: all .45s;
}

.Collection_zoom__img_wrapper__1Jw_8.Collection_active__29p6R .Collection_zoom__img__Xq4XG {
    -webkit-transform: scale(1);
            transform: scale(1);
}

.Collection_zoom__img__Xq4XG,
.Collection_zoom__img__Xq4XG img {
    width: 100%;
}

.Collection_zoom_close__d1noy {
    position: absolute;
    top: 25px;
    right: 25px;
    width: 30px;
    height: 30px;
}

.Collection_zoom_close__d1noy span {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 100%;
    height: 2px;
    background-color: #fff;
}

.Collection_zoom_close__d1noy span:first-child {
    -webkit-transform: translate(-50%, -50%) rotate(-45deg);
            transform: translate(-50%, -50%) rotate(-45deg);
}

.Collection_zoom_close__d1noy span:last-child {
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
            transform: translate(-50%, -50%) rotate(45deg);
}

.Collection_collection__top__2aJTR {
    padding-top: 0;
    margin-bottom: 40px;
    /*overflow: hidden;*/
}

.Collection_collection__top__2aJTR:hover {
    z-index: 3;
}

.Collection_collection__top__2aJTR.Collection_animate__kWYBB {
    -webkit-animation: Collection_animateSliders__1MPeI 1s;
            animation: Collection_animateSliders__1MPeI 1s;
}

@-webkit-keyframes Collection_animateSliders__1MPeI {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes Collection_animateSliders__1MPeI {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.Collection_collection__top_slider__3mSqT,
.Collection_collection__top_slider__3mSqT img {
    width: 100%;
}

.Collection_collection__top_slider__3mSqT img + div * {
    background: transparent !important;
}

.Collection_collection__top_slider__3mSqT img + div:nth-child(2) {
    background: #FFFFFF !important;
}

.Collection_collection__top_slider__3mSqT {
    position: relative;
    /*padding: 30px;*/
    -webkit-transition: all .45s;
    transition: all .45s;
    width: 100vw;
    -webkit-transform: translateX(-24px);
            transform: translateX(-24px);
}

@media (min-width: 767px) {
    .Collection_collection__top_slider__3mSqT {
        width: 100%;
        -webkit-transform: none;
                transform: none;
    }
}

.Collection_collection__top_slider__3mSqT.Collection_animate__kWYBB {
    /*animation: animateSliderScale 1s;*/
}

.Collection_collection__top_slider__3mSqT img ~ div img {
    width: 1200px !important;
    height: auto !important;
}

/*@keyframes animateSliderScale {*/
/*    0% {*/
/*        transform: scale(0);*/
/*    }*/
/*    100% {*/
/*        transform: scale(1);*/
/*    }*/
/*}*/

@media screen and (min-width: 992px) {
    .Collection_collection__top_slider__3mSqT {
        margin: 0 auto;
        width: 100%;
    }
}

@media screen and (min-width: 1200px) {


    .Collection_collection__top_slider__3mSqT img {
        cursor: none !important;
    }

    .Collection_collection__top_slider__3mSqT:hover .Collection_zoom__ajuLf {
        opacity: 0;
    }
}

.Collection_artList__AKzib {
    display: flex;
    justify-content: center;
    margin-top: 5px;
    width: 100vw;
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px);
}

.Collection_artList__AKzib span {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px;
    width: 80px;
    height: 80px;
    background-color: #fff;
    -webkit-transition: all .45s;
    transition: all .45s;
    cursor: pointer;
}

@media screen and (min-width: 1200px) {


    .Collection_artList__AKzib {
        margin-top: 0;
        width: auto;
        -webkit-transform: none;
                transform: none;
    }

    .Collection_artList__AKzib span {
        width: 100px;
        height: 100px;
    }
}

@media screen and (min-width: 1400px) {
    .Collection_artList__AKzib span {
        width: 155px;
        height: 155px;
        padding: 15px;
    }
}

.Collection_collection__btn_wrapper__ZgwFR {
    margin-top: 50px;
    padding-top: 25px;
    padding-bottom: 25px;
    background-color: #fff;
}

.Collection_collection__container__14XJz {
    position: relative;
    padding-top: 34px;
    background-color: rgba(242, 242, 246, 1);
}

.Collection_collection__container__14XJz.Collection_hidden__rwI5o {
    overflow: hidden;
}

.Collection_collection__bottom__2PApQ {
    z-index: 2;
}

@media screen and (min-width: 1200px) {
    .Collection_collection__btn_wrapper__ZgwFR {
        width: 50%;
        margin-left: 0;
        margin-top: -84px;
    }

    .Collection_collection__container__14XJz {
        display: flex;
        flex-direction: row-reverse;
        padding: 0;
    }

    .Collection_collection__top__2aJTR,
    .Collection_collection__bottom__2PApQ {
        width: 50%;
    }

    .Collection_collection__top__2aJTR {
        background: #fff;
        margin-bottom: 0;
    }

    .Collection_collection__bottom__2PApQ {
        position: relative;
        margin-bottom: 80px;
        padding-bottom: 80px;
        padding-top: 34px;
        padding-left: 80px;
        background: #fff;
    }

    .Collection_collection__bottom__2PApQ::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 475px;
        height: 100%;
        background-color: #F2F2F6;
    }
}

@media screen and (min-width: 1400px) {
    .Collection_collection__container__14XJz {
        background-color: #fff;
    }

    .Collection_collection__btn_wrapper__ZgwFR {
        margin-top: -76px;
        padding-left: 80px;
        padding-top: 0;
        padding-bottom: 40px;
    }

    .Collection_collection__bottom__2PApQ {
        margin-bottom: 170px;
        padding-bottom: 60px;
        padding-top: 0;
    }
}

.Collection_artCovers__3HowP p {
    text-align: center;
}

.Collection_artCovers__3HowP ul {
    justify-content: center;
}

.ArtList_artList__1_qln {
    display: flex;
    flex-wrap: wrap;
}

@media screen and (min-width: 1200px) {
    .ArtList_artList__1_qln {
        flex: 1 1;
    }
}

.ArtList_artList__1_qln.ArtList_lock__2VGIC,
.ArtList_artList__specifications__oMIz-.ArtList_lock__2VGIC {
    pointer-events: none;
    cursor: default;
}

.ArtList_artList__container__2JLvm {
    position: relative;
    margin-bottom: 5px;

}

@media screen and (min-width: 1200px) {
    .ArtList_artList__container__2JLvm {
        display: flex;
        margin-bottom: 25px;
    }
}

.ArtList_artList__1_qln li {
    margin-right: 15px;
    margin-bottom: 25px;
    cursor: pointer;
}

.ArtList_artList__1_qln li,
.ArtList_artList__1_qln li figure {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    -webkit-transition: all .45s;
    transition: all .45s;
}

.ArtList_artList__1_qln li::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 0;
    height: 1px;
    background-color: rgba(82,114,255,1);
    -webkit-transition: all .45s;
    transition: all .45s;
}

.ArtList_artList__1_qln li.ArtList_active__28mhN figure {
    -webkit-transform: translateY(-12px);
            transform: translateY(-12px);
}

.ArtList_artList__1_qln li.ArtList_active__28mhN::before {
    width: 100%;
}

.ArtList_artList__1_qln li img {
    width: 100%;
}

.ArtList_artList__text__2sivl {
    margin: 0 auto;
    text-align: center;
    max-width: 230px;
}

@media screen and (min-width: 376px) {
    .ArtList_artList__text__2sivl {
        max-width: 400px;
    }
}

.ArtList_artList__text__2sivl p {
    font-family: 'Istok Web', sans-serif;
    font-weight: 700;
    font-style: italic;
    font-size: 16px;
    line-height: 23px;
    color: rgba(89,89,89,1);
}

@media screen and (min-width: 767px) {
    .ArtList_artList__text__2sivl p {
        font-size: 18px;
    }
}

.ArtList_artList__btn__18XX6 {
    margin-top: 30px;
}

@media screen and (min-width: 767px) {
    .ArtList_artList__btn__18XX6 {
        margin-top: 30px;
    }
}

/* Modal */
.ArtList_artList__modal__1ZXqG {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    width: 100vw;
    background-color: #fff;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all .45s;
    transition: all .45s;
    z-index: 2;
}

.ArtList_artList__modal__1ZXqG.ArtList_show__35xKy {
    opacity: 1;
    visibility: visible;
}

.ArtList_artList__modal_header__2ymRh {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 23px 0;
}

.ArtList_artList__modal_header__2ymRh p {
    font-family: 'Avenir', sans-serif;
    font-weight: 900;
    font-size: 16px;
    line-height: 22px;
    color: rgba(0,139,255,0.3);
    letter-spacing: 2.83px;
    text-transform: uppercase;
}

.ArtList_artList__modal_chosen__3UR9D {
    position: relative;
    margin-left: 15px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    -webkit-transform-origin: center;
            transform-origin: center;
}

.ArtList_artList__modal_chosen__3UR9D::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    display: block;
    width: 47px;
    height: 47px;
    border-radius: 50%;
    border: 0.95px solid rgba(0,139,255,1);
}

.ArtList_artList__modal_chosen__3UR9D::after {
    content: '';
    position: absolute;
    right: -40px;
    top: 50%;
    -webkit-transform: translateY(-50%) rotate(180deg);
            transform: translateY(-50%) rotate(180deg);
    display: block;
    width: 15px;
    height: 15px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAASCAYAAAEyHv41AAAABGdBTUEAALGPC/xhBQAAAVtJREFUOBGtUztOw0AQfWMBElwAuANCHIAGUSJOAqURHRKUJJFouApFlONEIgW0VFGGGa/HO8brTxxGSvYz897bN7sGkjFhhv4GRlmaleVrHalYpEgeiEKlTKTspSjcwyV03RWEKT+D8VQrEmRKay10+7FwqCPCO5/gB58R2jPLkcVjT3klBzzugHzIuW40H0G6alMVdhBVN1EHKVAjqlbsIbHDvza7z4unL5RTF+GL/Ly6oPAYjnDqs8l5jgPbD6A7WlUvyDJ+1FfV2r20v0YHmy2vP+rKhxcOx/M73p/z4UuaIPP3x4cHEd74HBuc+c1/nW8wR05foQ8zvhaxuQg0TztedYlDXOCevpUiNpw5wwwL+aKuxnOXSMKjuHj1PFHIdndzV3NhlDo2hXR3jLuEC6WySAtZdpi7VhdGo2O3kFZ0uetxoXCLfiGrrLsb5MKg24/qbsK32wOBX3hUeoMQ+VnTAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    -webkit-transition: all .45s;
    transition: all .45s;
}

.ArtList_artList__modal_chosen__3UR9D img {
    width: 100%;
}
.ArtList_artList__modal_body__3Wq81 {
    padding: 45px 0 15px;
}

.ArtList_artList__modal_slider__2gSBv li img {
    width: 50px;
}

.ArtList_artList__modal_slider__2gSBv * {
    outline: none;
}

/* End Modal*/

.ArtList_artList__title__2wBtY {
    margin-bottom: 20px;
    font-family: "Tussilago", sans-serif;
    font-weight: 700;
    font-size: 12px;
    line-height: 19px;
    color: rgba(119,119,143,1);
    text-transform: uppercase;
}

@media screen and (min-width: 1200px) {
    .ArtList_artList__title__2wBtY {
        margin-bottom: 0;
        width: 37%;
        padding-right: 10px;
    }
}

@media screen and (min-width: 1400px) {
    .ArtList_artList__specifications__oMIz- {
        padding-left: 0;
    }
}

.ArtList_artList__specifications__oMIz- li {
    position: relative;
    padding-left: 0;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 15px;
    line-height: 29px;
    letter-spacing: -0.05px;
    text-align: left;
    color: rgba(40,40,58,1);
    -webkit-transition: all .45s;
    transition: all .45s;
    padding-left: 15px;
}
.ArtList_artList__specifications__oMIz- li.ArtList_first__2bA8U {
    padding-left: 0;
}
.ArtList_artList__specifications__oMIz- li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 7px;
    height: 1px;
    background-color: rgba(82,114,255,1);
}
.ArtList_artList__specifications__oMIz- li.ArtList_first__2bA8U::before {
    display: none;
}
.ArtList_artList__specifications__oMIz- li span {
    -webkit-transition: all .45s;
    transition: all .45s;
}

.ArtList_collection__options_title__2VtDn {
    margin: 17px 0 20px;
}

@media screen and (min-width: 1200px) {
    .ArtList_collection__options_wrapper__1TO2n {
        display: flex;
        align-items: flex-end;
        order: -1;
        margin-bottom: 50px;
    }

    .ArtList_collection__options_title__2VtDn {
        display: block;
        margin: 0;
    }

    .ArtList_artList__specifications__oMIz- {
        padding-left: 20px;
    }
    .ArtList_artList__specifications__oMIz- li.ArtList_first__2bA8U {
        padding-left: 0;
    }
    .ArtList_artList__specifications__oMIz- li {
        padding-left: 30px;
    }

}

.ArtList_collection__options__3ZnO- {
    display: flex;
    justify-content: center;
    margin-top: 12px;
    margin-bottom: 20px;
}

@media screen and (min-width: 1200px) {
    .ArtList_collection__options__3ZnO- {
        position: static;
        -webkit-transform: none;
                transform: none;
        top: 0;
        left: 0;
        width: auto;
        margin-bottom: 0;
    }
}

.ArtList_collection__option__2YhtU {
    position: relative;
    font-family: 'Tussilago', sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    line-height: 19px;
    color: rgba(40,40,58,1);
    -webkit-transition: all .45s;
    transition: all .45s;
    cursor: pointer;
}

.ArtList_collection__option__2YhtU:not(:last-child){
    margin-right: 30px;
}

.ArtList_collection__option__2YhtU span {
    display: block;
    -webkit-transition: all .45s;
    transition: all .45s;
}

.ArtList_collection__option__2YhtU.ArtList_active__28mhN {
    pointer-events: none;
}

.ArtList_collection__option__2YhtU.ArtList_active__28mhN span {
    -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
    color: rgba(82,114,255,1);
}

.ArtList_collection__option__2YhtU::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 0;
    height: 1px;
    background-color: rgba(82,114,255,1);
    -webkit-transition: all .45s;
    transition: all .45s;
}

.ArtList_collection__option__2YhtU.ArtList_active__28mhN::before {
    width: 100%;
}

.ArtList_artList__wrapper__3BNiJ {
    /*position: relative;*/
    z-index: 2;
}

@media screen and (min-width: 1200px) {
    .ArtList_artList__specifications_wrapper__2NTFn {
        display: flex;
    }

    .ArtList_artList__wrapper__3BNiJ {
        display: flex;
        flex-direction: column;
    }
}

.Story_storySection__a7K-i {
    background-repeat: no-repeat;
    background-position: -180px 30px;
    background-size: 100%;
}

.Story_storySection__a7K-i.Story_animateZlata__1qv4c {
    -webkit-transform: scale(2);
            transform: scale(2);
}

.Story_storySection__wrapper__1OKPY {
    position: relative;
    padding-top: 300px;
    opacity: 1;
    visibility: visible;
    -webkit-transition: all 1s linear;
    transition: all 1s linear;
}

.Story_storySection__wrapper__1OKPY::before {
    content: '';
    position: absolute;
    top: 0;
    left: -40%;
    display: block;
    width: 100%;
    height: 500px;
    -webkit-filter: grayscale(1);
            filter: grayscale(1);
    opacity: 0.4;
    background-image: url(/static/media/zlata_bg.2fa6c067.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.Story_storySection__wrapper__1OKPY.Story_animate__tbhA3::before {
    -webkit-filter: grayscale(0);
            filter: grayscale(0);
}

@media screen and (min-width: 577px) {
    .Story_storySection__wrapper__1OKPY {
        padding-top: 450px;
    }
}

@media screen and (min-width: 680px) {
    .Story_storySection__a7K-i {
        background-size: 100%;
    }
}

@media screen and (min-width: 680px) {
    .Story_storySection__a7K-i {
        background-size: 60%;
    }

    .Story_storySection__wrapper__1OKPY {
        padding-top: 300px;
    }
}

@media screen and (min-width: 1100px) {
    .Story_storySection__wrapper__1OKPY {
        padding-bottom: 100px;
    }
}

@media screen and (min-width: 1200px) {
    .Story_storySection__a7K-i {
        padding-bottom: 0;
        background-size: 33%;
        background-position: 50px;
    }

    .Story_storySection__wrapper__1OKPY::before {
        top: 40px;
        left: -51%;
        height: 100%;
        background-size: 50%;
        -webkit-transition: all 1s linear;
        transition: all 1s linear;
    }
}

@media screen and (min-width: 1400px) {
    .Story_storySection__a7K-i {
        background-size: 45%;
        background-position: 30px 160px;
    }

    .Story_storySection__wrapper__1OKPY {
        padding-top: 170px;
    }

    .Story_storySection__wrapper__1OKPY::before {
        left: -57%;
    }
}

@media screen and (min-width: 1600px) {
    .Story_storySection__a7K-i {
        background-position: 10% 160px;
        background-size: 720px;
    }

    .Story_storySection__wrapper__1OKPY::before {
        left: -40%;
    }
}

.Story_storySection__images_top__YRP0I {
    display: flex;
}

.Story_storySection__images_top__YRP0I figure {
    flex: 1 1;
    overflow: hidden;
    max-height: 150px;
    height: auto;
}

@media screen and (max-width: 374px) {
    .Story_storySection__images_top__YRP0I figure {
        max-height: 130px;
    }
}

@media screen and (min-width: 577px) {
    .Story_storySection__images_top__YRP0I figure {
        max-height: 240px;
    }
}

@media screen and (min-width: 992px) {
    .Story_storySection__images_top__YRP0I figure {
        max-height: 370px;
    }
}

.Story_storySection__images_top__YRP0I img {
    width: 100%;
    max-width: none;
    min-width: auto;
    height: auto;
}

@media screen and (min-width: 376px) {
    .Story_storySection__images_top__YRP0I img {
        width: 100%;
        height: auto;
    }
}


.Story_storySection__images_bottom__1x7T4 figure {
    height: 300px;
    overflow: hidden;
}

@media screen and (min-width: 577px) {
    .Story_storySection__images_bottom__1x7T4 figure {
        height: 600px;
    }
}

@media screen and (min-width: 1025px) {
    .Story_storySection__images_bottom__1x7T4 figure {
        height: 750px;
    }
}

.Story_storySection__images_bottom__1x7T4 img {
    width: 100%;
}

@media screen and (min-width: 376px) {
    .Story_storySection__images_bottom__1x7T4 img {
        width: 100%;
        height: auto;
    }
}

.Story_storySection__info__1XSYu {
    position: relative;
    padding: 25px 0;
    background-color: #fff;
}


@media screen and (min-width: 577px) {
    .Story_storySection__info__1XSYu {
        margin-left: auto;
        width: 70%;
    }
}

@media screen and (min-width: 1200px) {
    .Story_storySection__info__1XSYu {
        padding: 50px 0;
        width: 50%;
    }
}

@media screen and (min-width: 1400px) {
    .Story_storySection__info__1XSYu {
        padding: 120px 0;
    }
}

.Story_storySection__info_text__1dx0i p {
    font-family: 'Istok Web', sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 26px;
    letter-spacing: -0.04px;
    color: rgba(89,89,89,1);
}

@media screen and (min-width: 1400px) {
    .Story_storySection__info_text__1dx0i p {
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 32px;
        letter-spacing: -0.05px;
        color: #595959;
    }
}

.Story_storySection__author__2UeFC {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 45px;
}

.Story_storySection__author_img__1MS_Q {
    margin-right: 15px;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    overflow: hidden;
}

@media screen and (min-width: 767px) {
    .Story_storySection__author_img__1MS_Q {
        width: 70px;
        height: 70px;
    }
}

.Story_storySection__author_img__1MS_Q img,
.Story_storySection__author_signature__3WV0D img{
    width: 100%;
}

.Story_storySection__author_signature__3WV0D {
    display: flex;
    align-items: center;
    margin: 20px 0;
    width: 250px;
}

@media screen and (min-width: 1200px) {
    .Story_storySection__author_signature__3WV0D {
        -webkit-transform: translateX(-60px);
                transform: translateX(-60px);
    }
}

@media screen and (min-width: 1400px) {
    .Story_storySection__author_signature__3WV0D {
        width: 660px;
        -webkit-transform: translateX(-200px);
                transform: translateX(-200px);
    }
}

.Story_storySection__btn__3XmRU {
    margin-top: 30px;
}

@media screen and (min-width: 767px) {
    .Story_storySection__btn__3XmRU {
        width: 200px;
    }
}

@media screen and (min-width: 1400px) {
    .Story_storySection__btn__3XmRU {
        margin-top: 80px;
    }

    .Story_storySection__container__1FFy7 {
        padding: 0 60px 0 70px;
    }
}

.PremiumQuality_premiumQuality__1EgTH {
    padding: 25px 0;
    /*background-image: url('../../assets/img/quality-bg.png');*/
    background-repeat: no-repeat;
    background-position: top left;
    background-size: 350%;
    background-attachment: fixed;
}

@media screen and (min-width: 767px) {
    .PremiumQuality_premiumQuality__1EgTH {
        padding: 35px 0;
    }
}

@media screen and (min-width: 992px) {
    .PremiumQuality_premiumQuality__1EgTH {
        background-size: 100%;
    }
}

@media screen and (min-width: 1200px) {
    .PremiumQuality_premiumQuality__1EgTH {
        padding-top: 55px;
        padding-bottom: 15px;
    }
}

@media screen and (min-width: 1400px) {
    .PremiumQuality_premiumQuality__1EgTH {
        padding-top: 75px;
    }
}

.PremiumQuality_premiumQuality__img__1vYbH {
    margin-left: -20px;
    width: 250px;
}

@media screen and (max-width: 370px) {
    .PremiumQuality_premiumQuality__img__1vYbH {
        width: 290px;
    }
}

@media screen and (min-width: 376px) {
    .PremiumQuality_premiumQuality__img__1vYbH {
        margin-left: 0;
    }
}

@media screen and (min-width: 577px) {
    .PremiumQuality_premiumQuality__img__1vYbH {
        width: 350px;
    }
}

@media screen and (min-width: 767px) {
    .PremiumQuality_premiumQuality__img__1vYbH {
        width: 420px;
    }
}

.PremiumQuality_premiumQuality__img__1vYbH img {
    width: 100%;
}

.PremiumQuality_premiumQuality__wrapper__20iLq {
    display: flex;
}

.PremiumQuality_premiumQuality__list__2LM0L {
    padding-left: 10px;
    flex: 1 1;
}

@media screen and (max-width: 370px) {
    .PremiumQuality_premiumQuality__list__2LM0L {
        margin-right: 10px;
        padding-left: 10px;
    }
}

@media screen and (min-width: 376px) {
    .PremiumQuality_premiumQuality__list__2LM0L {
        padding-left: 20px;
    }
}

.PremiumQuality_premiumQuality__list_item__2yPgN {
    margin-bottom: 22px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    max-width: 120px;
    text-align: left;
    text-transform: uppercase;
    color: rgba(0,139,255,1);
}

@media screen and (max-width: 370px) {
    .PremiumQuality_premiumQuality__list_item__2yPgN {
        font-size: 10px;
        line-height: 14px;
    }
}

@media screen and (min-width: 360px) and (max-width: 370px) {
    .PremiumQuality_premiumQuality__list_item__2yPgN {
        margin-bottom: 16px;
    }
}

@media screen and (min-width: 376px) {
    .PremiumQuality_premiumQuality__list_item__2yPgN {
        max-width: 160px;
    }
}

@media screen and (min-width: 577px) {
    .PremiumQuality_premiumQuality__list_item__2yPgN {
        margin-bottom: 45px;
    }
}

@media screen and (min-width: 767px) {
    .PremiumQuality_premiumQuality__list_item__2yPgN {
        font-size: 16px;
        line-height: 22px;
        max-width: 170px;
    }
}

.PremiumQuality_premiumQuality__list_item__2yPgN.PremiumQuality_push_system__1U1Xj {
    margin-bottom: 50px;
}

@media screen and (max-width: 370px) {
    .PremiumQuality_premiumQuality__list_item__2yPgN.PremiumQuality_push_system__1U1Xj {
        margin-bottom: 32px;
    }
}

@media screen and (min-width: 360px) and (max-width: 370px) {
    .PremiumQuality_premiumQuality__list_item__2yPgN.PremiumQuality_push_system__1U1Xj {
        margin-bottom: 40px;
    }
}

@media screen and (min-width: 577px) {
    .PremiumQuality_premiumQuality__list_item__2yPgN.PremiumQuality_push_system__1U1Xj {
        margin-bottom: 90px;
    }
}

.PremiumQuality_premiumQuality__list_item__2yPgN.PremiumQuality_axis__3XvCn {
    margin-bottom: 10px;
}

@media screen and (min-width: 577px) {
    .PremiumQuality_premiumQuality__list_item__2yPgN.PremiumQuality_axis__3XvCn {
        margin-bottom: 40px;
    }
}

.PremiumQuality_premiumQuality__title_bottom__3YBD0 {
    margin-top: 55px;
    line-height: 32px;
}

.PremiumQuality_premiumQuality__header_container__QaOrL {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
}

.PremiumQuality_premiumQuality__header_img__7fApg {
    margin-right: 17px;
    width: 72px;
    height: auto;
}

.PremiumQuality_premiumQuality__header_img__7fApg img {
    width: 100%;
}

/* New Dis */
.PremiumQuality_premiumQuality__items__15Hkh {
    margin-top: 25px;
    width: 100%;
}

@media screen and (min-width: 992px) {
    .PremiumQuality_premiumQuality__items__15Hkh {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}

.PremiumQuality_premiumQuality__item__3DP-r {
    margin-bottom: 25px;
    width: 100%;
    opacity: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    -webkit-transition: all 2s;
    transition: all 2s;
}

.PremiumQuality_premiumQuality__item__3DP-r.PremiumQuality_animate__2ziq5 {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
}

@media screen and (min-width: 992px) {
    .PremiumQuality_premiumQuality__item__3DP-r {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
        width: 47%;
    }
}

.PremiumQuality_premiumQuality__item_header__1czj1 {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.PremiumQuality_premiumQuality__item_img__7ksu9 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    padding: 10px;
    width: 88px;
    height: 88px;
    overflow: hidden;
}

@media screen and (min-width: 1200px) {
    .PremiumQuality_premiumQuality__item_img__7ksu9 {
        width: 135px;
        height: 135px;
    }
}

@media screen and (min-width: 1400px) {
    .PremiumQuality_premiumQuality__item_img__7ksu9 {
        margin-right: 20px;
    }
}

.PremiumQuality_premiumQuality__item_img__7ksu9.PremiumQuality_empty__pF5tV {
    border: 1px solid rgba(82,114,255,1);
}

.PremiumQuality_premiumQuality__item_img__7ksu9 img {
    width: 100%;
}

.PremiumQuality_premiumQuality__item__3DP-r h3 {
    margin-bottom: 5px;
    font-family: 'Tussilago', sans-serif;
    font-weight: 700;
    font-size: 12px;
    line-height: 17px;
    text-transform: uppercase;
    color: rgba(82,114,255,1);
}

@media screen and (min-width: 1400px) {
    .PremiumQuality_premiumQuality__item__3DP-r h3 {
        font-size: 18px;
        line-height: 29px;
        letter-spacing: -0.06px;
    }
}

.PremiumQuality_premiumQuality__item_info__1tUBv p {
    margin:  0 auto;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: rgba(89,89,89,1);
}

@media screen and (min-width: 1400px) {
    .PremiumQuality_premiumQuality__item_info__1tUBv p {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.04px;
    }
}
.Comments_commentsSection__EnSRz {
    margin-top: 40px;
    background-image: url(/static/media/feedback-bg.806a7bef.jpg);
    background-repeat: no-repeat;
    background-position: 50% -50px;
    background-size: cover;
}

@media screen and (min-width: 1200px) {
    .Comments_commentsSection__EnSRz {
        padding-bottom: 150px;
        background-position: 50% -80px;
    }
}

@media screen and (min-width: 1400px) {
    .Comments_commentsSection__EnSRz {
        background-position: 0 -120px;
    }
}

.Comments_commentsSection__header__2OaTM {
    padding-bottom: 35px;
}

@media screen and (min-width: 767px) {
    .Comments_commentsSection__header__2OaTM {
        padding: 35px 0;
    }
}

@media screen and (min-width: 1400px) {
    .Comments_commentsSection__header__2OaTM {
        padding: 90px 0;
    }
}

.Comments_commentsSection__header_container__bx8cx {
    display: flex;
    flex-direction: column;
}

.Comments_commentsSection__header_img__qc5M4 {
    display: flex;
    align-items: center;
    margin-right: 24px;
    width: 72px;
    height: auto;
}

@media screen and (min-width: 767px) {
    .Comments_commentsSection__header_img__qc5M4 {
        margin-bottom: 25px;
    }
}

.Comments_commentsSection__header_img__qc5M4 img {
    width: 100%;
}

.Comments_comments__3ZyyQ {
    padding-bottom: 120px;
}

@media screen and (min-width: 1100px) {
    .Comments_comments__3ZyyQ {
        display: flex;
    }
}

@media screen and (min-width: 1200px) {
    .Comments_comments__3ZyyQ {
        margin: 0 auto;
        padding: 0 24px;
        max-width: 1280px;
        width: 100%;
    }
}

.Comments_comment__header__3VLpD {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    padding: 0 30px;
}

.Comments_comment__header__3VLpD::before {
    content: '';
    position: absolute;
    bottom: -15px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    display: block;
    width: 105%;
    height: 1px;
    background-color: rgba(82,114,255,1);
}

.Comments_comment__img__3e57I {
    margin-right: 15px;
    width: 53px;
    height: 53px;
    border-radius: 50%;
    overflow: hidden;
}

.Comments_comment__img__3e57I img {
    width: 100%;
}

.Comments_comment__author__3hJp0 {
    font-family: 'Tussilago', sans-serif;
    font-weight: bold;
    letter-spacing: -0.06px;
    font-size: 18px;
    line-height: 24px;
    color: rgba(82,114,255,1);
}

.Comments_comment__body__1yQ4f {
    padding: 0 30px;
}

.Comments_comment__body__1yQ4f p {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 14px;
    line-height: 23px;
    letter-spacing: -0.04px;
    color: rgba(89,89,89,1);
}

@media screen and (min-width: 1400px) {
    .Comments_comment__body__1yQ4f p {
        font-size: 16px;
        line-height: 25px;
    }
}

.Comments_commentsSection__footer__1RfYd {
    padding: 15px 0;
    border: 1px solid rgba(255,255,255,0.12);
    background-color: rgba(52,61,115,1);
}

@media screen and (min-width: 767px) {
    .Comments_commentsSection__footer__1RfYd {
        padding: 35px 0;
    }
}

.Comments_comments__btn__1I_PB {
    position: relative;
    width: 100px;
    height: 50px;
    border: 1px solid #4A4F70;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    -webkit-transition: all .45s;
    transition: all .45s;
}

@media screen and (min-width: 767px) {
    .Comments_comments__btn__1I_PB {
        width: 150px;
    }
}

.Comments_comments__btn_prev__1qRZb {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
}

.Comments_comments__btn_next__2MuGn {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
}

.Comments_comments__btn_next__2MuGn::before,
.Comments_comments__btn_prev__1qRZb::before {
    content: '';
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    display: block;
}

.Comments_comments__btn_next__2MuGn:hover::before,
.Comments_comments__btn_prev__1qRZb:hover::before {
    border-left-color: #ffffff;
    border-right-color: #ffffff;
}

.Comments_comments__btn_next__2MuGn::before {
    right: 40px;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-left: 7px solid #008EFF;
    border-bottom: 5px solid transparent;
}

.Comments_comments__btn_prev__1qRZb::before {
    right: auto;
    left: 40px;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-right: 7px solid #008EFF;
    border-bottom: 5px solid transparent;
}

.Comments_comments__btn__1I_PB:hover {
    border-color: #008EFF;
    background-color: #008EFF;
}

.Comments_comment__1a1r9 {
    margin: 0 7px;
    padding: 30px 0;
    width: 90vw;
    background-color: rgba(255,255,255,1);
}

@media screen and (min-width: 768px) {
    .Comments_comment__1a1r9 {
        width: 70vw;
    }
}

@media screen and (min-width: 1200px) {
    .Comments_comment__1a1r9 {
        opacity: 0;
        -webkit-transition: all 2s;
        transition: all 2s;
    }

    .Comments_comment__1a1r9:nth-child(1) {
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
    }

    .Comments_comment__1a1r9:nth-child(2) {
        -webkit-transform: translateX(0) scale(0);
                transform: translateX(0) scale(0);
    }
    .Comments_comment__1a1r9:nth-child(3) {
        -webkit-transform: translateX(100%);
                transform: translateX(100%);
    }

    .Comments_comment__1a1r9.Comments_animate__1fJ1d {
        opacity: 1;
        -webkit-transform: translateX(0%) scale(1);
                transform: translateX(0%) scale(1);
        /*animation: commentScale 1s ease-in-out both;*/
    }

    .Comments_comment__1a1r9:first-of-type {
        -webkit-animation-delay: 0.25s;
                animation-delay: 0.25s;
    }

    .Comments_comment__1a1r9:last-of-type {
        -webkit-animation-delay: 0.5s;
                animation-delay: 0.5s;
    }

    /*@keyframes commentScale {*/
    /*    0% {*/
    /*        transform: scale(0);*/
    /*        opacity: 0;*/
    /*    }*/
    /*    60% {*/
    /*        transform: scale(1.1);*/
    /*        opacity: 1*/
    /*    }*/
    /*    80% {*/
    /*        transform: scale(0.95);*/
    /*        opacity: 1;*/
    /*    }*/
    /*    100% {*/
    /*        transform: scale(1);*/
    /*        opacity: 1;*/
    /*    }*/
    /*}*/
}

.HomePage_homePage__comments__2JYO- {
    background-color: #F7F7F7;
}

.HomePage_homePage__3mg2e {
    /*opacity: 0;*/
    /*visibility: hidden;*/
    /*transition: all .45s;*/
    height: 0;
    overflow: hidden;
    /*background-color: #F7F7F7;*/
}

.HomePage_homePage__3mg2e.HomePage_pageIsLoaded__3J2M1 {
    opacity: 1;
    height: auto;
    visibility: visible;
}

.Stores_stores__38XPK {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    padding-bottom: 50px;
    /*background-color: #F7F7F7;*/
    overflow: hidden;
}
.Stores_stores__header__2usDE span {
    font-size: 16px;
    line-height: 27px;
    letter-spacing: -.5px;
    color: rgba(82,82,82,1);
    display: inline-block;
    margin-bottom: 10px;
}

@media screen and (min-width: 992px) {
    .Stores_stores__38XPK {
        padding-top: 25px;
    }
}

@media screen and (min-width: 1200px) {
    .Stores_stores__38XPK {
        padding-top: 200px;
        padding-bottom: 100px;
    }
}

.Stores_stores__list__3AQRN {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 55px;
    grid-gap: 10px;
}

.Stores_stores__list__3AQRN li {
    grid-row: span 2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 155px;
    height: auto;
    background-color: #ffffff;
    box-shadow: 0 1px 10px 0 rgba(24,24,24,0.16);
    opacity: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    visibility: hidden;
    -webkit-transition: all .15s, -webkit-transform .45s;
    transition: all .15s, -webkit-transform .45s;
    transition: all .15s, transform .45s;
    transition: all .15s, transform .45s, -webkit-transform .45s;
}

.Stores_stores__list__3AQRN li.Stores_animate__1IEDb {
    opacity: 1;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    visibility: visible;
}

.Stores_stores__list__3AQRN li:first-child {
    grid-row: 2 / 4;
}

@media screen and (min-width: 768px) {
    .Stores_stores__list__3AQRN {
        grid-auto-rows: 75px;
    }
}

@media screen and (min-width: 1200px) {
    .Stores_stores__list__3AQRN {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 30px;
        margin-top: -70px;
    }

    .Stores_stores__list__3AQRN li {
        box-shadow: 0 2px 50px 0 rgba(24,24,24,0.05);
        max-width: none;
    }

    .Stores_stores__list__3AQRN li:hover {
        -webkit-transform: scale(1.05);
                transform: scale(1.05);
        box-shadow: 0 2px 50px 0 rgba(24,24,24,0.2);
    }

    .Stores_stores__list__3AQRN li:hover .Stores_stores__list_span__YjNzo::before {
        opacity: 1;
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }

    .Stores_stores__list__3AQRN li:active {
        box-shadow: 0 2px 50px 0 rgba(24,24,24,0.05);
    }

    .Stores_stores__list__3AQRN li:first-child {
        grid-row: 3 / 5;
    }

    .Stores_stores__list__3AQRN li:nth-child(2) {
        grid-row: 2 / 4;
    }

    .Stores_stores__list__3AQRN li:nth-child(3) {
        grid-row: 4 / 6;
    }

    .Stores_stores__list__3AQRN li:nth-child(4) {
        grid-row: 1 / 3;
        grid-column: 3;
    }

    .Stores_stores__list__3AQRN li:nth-child(5) {
        grid-column: 3;
    }

    .Stores_stores__list_span__YjNzo {
        position: relative;
    }

    .Stores_stores__list_span__YjNzo::before {
        content: '';
        position: absolute;
        bottom: -50px;
        left: 0;
        display: block;
        width: 70px;
        height: 20px;
        background-image: url(/static/media/arrow_mob_right_blue.ad39cdb7.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
        opacity: 0;
        -webkit-transition: all .45s;
        transition: all .45s;
    }
}

@media screen and (min-width: 1400px) {
    .Stores_stores__list__3AQRN {
        grid-auto-rows: 115px;
        margin-top: -100px;
    }
}

.Stores_stores__list__3AQRN a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

li.Stores_stores__list_seller__12ZOz {
    position: relative;
    border: 1px solid rgba(82,114,255,1);
    background: transparent;
    box-shadow: 0 2px 50px 0 rgba(24,24,24,0.05);
}

@media screen and (min-width: 577px) {
    li.Stores_stores__list_seller__12ZOz br {
        display: none;
    }
}

@media screen and (min-width: 1200px) {
    li.Stores_stores__list_seller__12ZOz:hover {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

.Stores_stores__list_seller__12ZOz a {
    padding-left: 20px;
    font-family: 'Tussilago', sans-serif;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: -0.04px;
    color: rgba(82,114,255,1);
}

@media screen and (min-width: 768px) {
    .Stores_stores__list_seller__12ZOz a {
        font-size: 14px;
        line-height: 16px;
    }
}


.Stores_stores__list_circle__1iwtO {
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
}

.Stores_stores__list_circle__1iwtO::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 50%;
    height: 100%;
    border-radius: 0 100% 100% 0 / 0 50% 50% 0;
    background: rgba(82,114,255,1);
    -webkit-transform-origin: center left;
            transform-origin: center left;
    -webkit-transition: all .45s;
    transition: all .45s;
}

@media screen and (max-width: 350px) {
    .Stores_stores__list_circle__1iwtO {
        left: -5px;
    }
}

@media screen and (min-width: 577px) {
    .Stores_stores__list_circle__1iwtO {
        left: 30px;
    }
}

.Stores_stores__list__3AQRN li {

}

.Stores_stores__list__3AQRN li img {
    height: 30px;
    width: 100px;
    object-fit: contain;
}

.Stores_stores__list__3AQRN li:nth-child(4) img {
    height: 15px;
}

@media screen and (min-width: 1400px) {
    .Stores_stores__list__3AQRN li img {
        height: 50px;
        width: 200px;
    }

    .Stores_stores__list__3AQRN li:nth-child(4) img {
        height: 30px;
    }
}

.About_container__JRJGi {
    margin: 0 auto;
    padding: 0 24px;
    max-width: 1280px;
    width: 100%;
}

.About_about__2XuXW {
    /*opacity: 0;*/
    /*visibility: hidden;*/
    height: 0;
    /*overflow: hidden;*/
    -webkit-transition: all .25s;
    transition: all .25s;
}

.About_about__2XuXW.About_pageIsLoaded__1Q7Ds {
    /*opacity: 1;*/
    /*visibility: visible;*/
    height: auto;
}

.About_about__title__2qDzS {
    margin-bottom: 30px;
    font-family: 'Syncopate', sans-serif;
    font-weight: bold;
    font-size: 26px;
    line-height: 28px;
    letter-spacing: -0.15px;
    color: #1D223D;
}

.About_about__subtitle__MRpoj {
    margin-bottom: 15px;
    font-family: 'Tussilago', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    letter-spacing: -0.08px;
    color: #5272FF;
}

.About_text__3FM1w {
    position: relative;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.05px;
    color: #525252;
    z-index: 2;
}

.About_text__3FM1w p {
    margin-bottom: 15px;
}

.About_columns__img__30Pbf {
    position: relative;
    margin-bottom: 15px;
    overflow: hidden;
    height: auto;
    min-height: 200px;
    max-height: none;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% auto;
    background-attachment: unset;
}

.About_about__center__cBScI .About_columns__img__30Pbf,
.About_about__center__cBScI .About_columns__img__30Pbf img {
    height: auto;
    max-height: 650px;
}

.About_columns__img__30Pbf img {
    width: 100%;
    max-height: 300px;
    object-fit: cover;
    object-position: center;
}

.About_columns__3Dlry {
    display: flex;
    flex-direction: column;
}

.About_columns__3Dlry.About_img_first__UVpjo {
    flex-direction: column-reverse;
}

.About_fullImg__jMtDI {
    margin-bottom: 15px;
    height: 300px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: -2;
}

.About_about__top__UTT8S {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-top: 100px;
}

.About_back_btn__wrapper__2dCva {
    position: fixed;
    top: 20px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    display: flex;
    justify-content: flex-end;
    padding: 0 24px;
    max-width: 970px;
    width: 100%;
    z-index: 2;
}

.About_back_btn__v7i1j {
    display: inline-block;
    margin-left: auto;
    padding: 10px 10px 10px 70px;
    font-family: 'Syncopate', sans-serif;
    font-weight: bold;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: -0.04px;
    text-decoration: none;
    color: #5272FF;
    background-image: url(/static/media/back_arrow.7446d62d.svg);
    background-repeat: no-repeat;
    background-position: 0% 49%;
    background-size: 60px;
    -webkit-transition: all .45s;
    transition: all .45s;
}

.About_back_btn__v7i1j:hover {
    padding-left: 100px;
}

.About_back_btn__v7i1j:active {
    padding-left: 70px;
}

.About_letters__3lTkx {
    display: none;
}

.About_about__title__2qDzS,
.About_about__subtitle__MRpoj,
.About_animated_top_text__qM94h,
.About_columns__img_top__2W1ek {
    -webkit-animation-delay: 3s;
            animation-delay: 3s;
}

.About_columns__img_top__2W1ek {
    opacity: 0;
    -webkit-transition: all 4s ease;
    transition: all 4s ease;
}

.About_columns__img_top__2W1ek.About_animate__1XMXG {
    opacity: 1;
}


@media screen and (min-width: 768px) {
    .About_about__subtitle__MRpoj {
        margin-bottom: 25px;
        max-width: 780px;
    }

    .About_about__bottom_subtitle__23fv2 {
        max-width: 850px;
    }

    .About_columns__3Dlry {
        margin-bottom: 25px;
    }

    .About_columns__3Dlry,
    .About_columns__3Dlry.About_img_first__UVpjo {
        flex-direction: row;
    }

    .About_columns__img__30Pbf {
        margin-bottom: 0;
        width: 50%;
    }

    .About_text__3FM1w {
        margin-bottom: 0;
        padding-right: 30px;
        width: 50%;
    }

    .About_columns__3Dlry.About_img_first__UVpjo .About_text__3FM1w {
        padding-right: 0;
        padding-left: 30px;
    }

    .About_fullImg__jMtDI {
        margin-bottom: 25px;
    }
}

@media screen and (min-width: 1200px) {
    .About_container__JRJGi,
    .About_back_btn__wrapper__2dCva {
        padding-left: 0;
        padding-right: 0;
    }

    .About_container__JRJGi,
    .About_about__2XuXW {
        position: relative;
    }

    .About_about__2XuXW::before {
        content: '';
        position: absolute;
        top: 90px;
        left: -345px;
        display: block;
        width: 100%;
        height: 800px;
        /*
        background-image: url('../../assets/img/zphoto.png');
        background-repeat: no-repeat;
        background-position: center left;
        background-size: contain;
         */
        opacity: 0.4;
        z-index: -1;
        /*transition: all .45s;*/
    }

    .About_columns__img__30Pbf {
        overflow: visible;
        height: 300px;
    }

    .About_columns__img__30Pbf,
    .About_fullImg__jMtDI {
        position: relative;
        background-attachment: fixed;
    }

    .About_letter__cSJHu {
        display: block;
        width: 90px;
        height: 90px;
        overflow: hidden;
    }

    .About_letters__3lTkx {
        position: absolute;
        display: block;
        width: 90px;
        height: 90px;
    }

    .About_letters__3lTkx.About_first__1g7U- {
        left: 0;
        bottom: -80px;
    }
    .About_letters__3lTkx.About_first__2__12mfU {
        right: 0;
        bottom: -48px;
    }

    .About_letters__3lTkx.About_second__3kt3a {
        top: 20px;
        left: 0;
    }

    .About_letters__3lTkx.About_third__1xWj1 {
        top: 100px;
        right: -45px;
        z-index: -1;
    }

    .About_letters__3lTkx.About_fourth__KwR2M {
        top: 100px;
        left: -100px;
    }

    .About_letters__3lTkx.About_fifth__1vBKr {
        top: 300px;
        right: 0;
    }
}

@media screen and (min-width: 1400px) {
    .About_about__top__UTT8S {
        padding-top: 190px;
    }

    .About_about__2XuXW::before {
        top: 215px;
        left: -350px;
    }

    .About_back_btn__v7i1j {
        font-size: 12px;
    }

    .About_about__title__2qDzS {
        margin-bottom: 50px;
        font-size: 48px;
        line-height: 50px;
    }

    .About_about__subtitle__MRpoj {
        margin-bottom: 40px;
        font-size: 24px;
        line-height: 30px;
    }

    .About_text__3FM1w {
        padding-right: 50px;
        font-size: 16px;
        line-height: 27px;
    }

    .About_text__3FM1w p {
        margin-bottom: 25px;
    }

    .About_columns__3Dlry.About_img_first__UVpjo .About_text__3FM1w {
        padding-right: 0;
        padding-left: 50px;
    }

    .About_columns__3Dlry {
        margin-bottom: 50px;
    }

    .About_fullImg__jMtDI {
        margin-bottom: 70px;
        height: 790px;
    }
}

.Stores_stores__1wqLE {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    padding-bottom: 50px;
    /*background-color: #F7F7F7;*/
    overflow: hidden;
}
.Stores_stores__header__3JHmp span {
    font-size: 16px;
    line-height: 27px;
    letter-spacing: -.5px;
    color: rgba(82,82,82,1);
    display: inline-block;
    margin-bottom: 10px;
}

@media screen and (min-width: 992px) {
    .Stores_stores__1wqLE {
        padding-top: 25px;
    }
}

@media screen and (min-width: 1200px) {
    .Stores_stores__1wqLE {
        padding-top: 120px;
        padding-bottom: 100px;
    }
}

.Stores_stores__list__2ugDn {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 55px;
    grid-gap: 10px;
}

.Stores_stores__list__2ugDn li {
    grid-row: span 2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 155px;
    height: auto;
    background-color: #ffffff;
    box-shadow: 0 1px 10px 0 rgba(24,24,24,0.16);
    opacity: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    visibility: hidden;
    -webkit-transition: all .15s, -webkit-transform .45s;
    transition: all .15s, -webkit-transform .45s;
    transition: all .15s, transform .45s;
    transition: all .15s, transform .45s, -webkit-transform .45s;
}

.Stores_stores__list__2ugDn li.Stores_animate__1obNT {
    opacity: 1;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    visibility: visible;
}

.Stores_stores__list__2ugDn li:first-child {
    grid-row: 2 / 4;
}

@media screen and (min-width: 768px) {
    .Stores_stores__list__2ugDn {
        grid-auto-rows: 75px;
    }
}

@media screen and (min-width: 1200px) {
    .Stores_stores__list__2ugDn {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 30px;
        margin-top: -70px;
    }

    .Stores_stores__list__2ugDn li {
        box-shadow: 0 2px 50px 0 rgba(24,24,24,0.05);
        max-width: none;
    }

    .Stores_stores__list__2ugDn li:hover {
        -webkit-transform: scale(1.05);
                transform: scale(1.05);
        box-shadow: 0 2px 50px 0 rgba(24,24,24,0.2);
    }

    .Stores_stores__list__2ugDn li:hover .Stores_stores__list_span__2i_As::before {
        opacity: 1;
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }

    .Stores_stores__list__2ugDn li:active {
        box-shadow: 0 2px 50px 0 rgba(24,24,24,0.05);
    }

    .Stores_stores__list__2ugDn li:first-child {
        grid-row: 3 / 5;
    }

    .Stores_stores__list__2ugDn li:nth-child(2) {
        grid-row: 2 / 4;
    }

    .Stores_stores__list__2ugDn li:nth-child(3) {
        grid-row: 4 / 6;
    }

    .Stores_stores__list__2ugDn li:nth-child(4) {
        grid-row: 1 / 3;
        grid-column: 3;
    }

    .Stores_stores__list__2ugDn li:nth-child(5) {
        grid-column: 3;
    }

    .Stores_stores__list_span__2i_As {
        position: relative;
    }

    .Stores_stores__list_span__2i_As::before {
        content: '';
        position: absolute;
        bottom: -50px;
        left: 0;
        display: block;
        width: 70px;
        height: 20px;
        background-image: url(/static/media/arrow_mob_right_blue.ad39cdb7.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
        opacity: 0;
        -webkit-transition: all .45s;
        transition: all .45s;
    }
}

@media screen and (min-width: 1400px) {
    .Stores_stores__list__2ugDn {
        grid-auto-rows: 115px;
        margin-top: -100px;
    }
}

.Stores_stores__list__2ugDn a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

li.Stores_stores__list_seller__1DdrN {
    position: relative;
    border: 1px solid rgba(82,114,255,1);
    background: transparent;
    box-shadow: 0 2px 50px 0 rgba(24,24,24,0.05);
}

@media screen and (min-width: 577px) {
    li.Stores_stores__list_seller__1DdrN br {
        display: none;
    }
}

@media screen and (min-width: 1200px) {
    li.Stores_stores__list_seller__1DdrN:hover {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

.Stores_stores__list_seller__1DdrN a {
    padding-left: 20px;
    font-family: 'Tussilago', sans-serif;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: -0.04px;
    color: rgba(82,114,255,1);
}

@media screen and (min-width: 768px) {
    .Stores_stores__list_seller__1DdrN a {
        font-size: 14px;
        line-height: 16px;
    }
}


.Stores_stores__list_circle__3fu8b {
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
}

.Stores_stores__list_circle__3fu8b::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 50%;
    height: 100%;
    border-radius: 0 100% 100% 0 / 0 50% 50% 0;
    background: rgba(82,114,255,1);
    -webkit-transform-origin: center left;
            transform-origin: center left;
    -webkit-transition: all .45s;
    transition: all .45s;
}

@media screen and (max-width: 350px) {
    .Stores_stores__list_circle__3fu8b {
        left: -5px;
    }
}

@media screen and (min-width: 577px) {
    .Stores_stores__list_circle__3fu8b {
        left: 30px;
    }
}

.Stores_stores__list__2ugDn li {

}

.Stores_stores__list__2ugDn li img {
    height: 30px;
    width: 100px;
    object-fit: contain;
}

.Stores_stores__list__2ugDn li:nth-child(4) img {
    height: 15px;
}

@media screen and (min-width: 1400px) {
    .Stores_stores__list__2ugDn li img {
        height: 50px;
        width: 165px;
    }

    .Stores_stores__list__2ugDn li:nth-child(4) img {
        height: 30px;
    }
}

.Policy_policy__1gKpD {
    background: #F7F7F7;
    padding-top: 50px;
    margin-bottom: 30px;
}

.Policy_policy__container__27Q3p {
    background-color: #FFFFFF;
    position: relative;
    z-index: 3;
    padding: 40px;

}

.Policy_policy__container_hide__2F501 {
    z-index: 2 !important;
}

.Policy_policy__title__2KrfU {
    font-family: 'Tussilago', sans-serif;
    color: #1D223D;
    font-weight: bold;
    margin-bottom: 20px;
    font-size: 30px;
    line-height: 36px;
}

.Policy_policy__info__2U9sE {
    margin-bottom: 30px;
}

.Policy_policy__text__1A9vL {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -.5px;
    color: #525252;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 20px;
}

.Policy_policy__text__1A9vL:last-child {
    margin-bottom: 0;
}

.Policy_policy__text__1A9vL a {
    color: #5272FF;
    border-bottom: 1px solid #5272FF;
    text-decoration: none;
}

.Policy_policy__text_strong__YlGVI {
    font-weight: bold;
    color: #525252;
}

.Policy_policy__name__Gn6vD {
    color: #5272FF;
    font-family: 'Tussilago', sans-serif;
    font-weight: bold;
    margin-bottom: 10px;
}

.Policy_policy__list__3aLu2 {
    margin-left: 30px;
}

.Policy_policy__item__3gKPN {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -.5px;
    font-family: 'Montserrat', sans-serif;
    color: #525252;
    list-style: disc;
}

@media screen and (min-width: 1200px) {
    .Policy_policy__1gKpD {
        margin-top: 135px;
        margin-bottom: 60px;
    }


    .Policy_policy__container__27Q3p {
        box-shadow: 0 0 36px 0 rgba(0, 0, 0, 0);
        padding: 90px 185px 120px;
    }


    .Policy_policy__title__2KrfU {
        font-size: 48px;
        line-height: 55px;
        letter-spacing: -.15px;
        margin-bottom: 40px;
    }

    .Policy_policy__name__Gn6vD {
        font-size: 24px;
        line-height: 30px;
        letter-spacing: -.08px;
    }

    .Policy_policy__item__3gKPN {

    }
}

.Footer_footer__V1QIA {
    position: relative;
    background-color: #2A2B58;
    background-image: url(/static/media/bg_bot.c6aa59af.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.Footer_footerImg__3qjjV {
    display: none;
}
.Footer_footerImg__3qjjV img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}
.Footer_footerTop__2DAOp {
    padding: 55px 0;
}

.Footer_footerBottom__2-T9z {
    padding: 20px 0;
    background-color: rgba(151, 151, 151, .04);
}

.Footer_footerBottom__content__RSA6m {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.Footer_footerTop__description__25YOI {
    display: block;
    font-family: 'Montserrat', sans-serif;
    max-width: 400px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -.05px;
    color: #fff;
}

@media screen and (min-width: 1400px) {
    .Footer_footer-top__description__200B6 {
        display: block;
        font-family: 'Montserrat', sans-serif;
        max-width: 450px;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -.05px;
        color: #fff;
    }
}

.Footer_footer__V1QIA::before {
    content: '';
    position: absolute;
    left: 0;
    top: 45px;
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
    display: block;
    width: 20px;
    height: 80px;
    background-image: url(/static/media/zontjk_logo_bot_big.89a392f1.svg);
    background-repeat: no-repeat;
    background-position: center right;
    background-size: contain;
}

.Footer_footer__copyright__185rr p {
    font-family: 'Tussilago', sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 15px;
    text-transform: uppercase;
    letter-spacing: -0.04px;
    color: rgba(255, 255, 255, 0.8);
}

.Footer_footer__copyright__185rr span {
    opacity: 0.4;
    color: rgba(255, 255, 255, 1);
    font-family: "Montserrat", sans-serif;
    font-size: 11px;
    letter-spacing: -0.04px;
    line-height: 19px;
}

.Footer_footer__socials__2oUcQ {
    display: flex;
    justify-content: center;
    margin: 35px 0;
}

@media screen and (min-width: 1200px) {
    .Footer_footer__socials__2oUcQ {
        margin: 5px 0;
        margin-left: 10%;
    }

    .Footer_footer__socials__2oUcQ a {
        padding: 10px;
    }
}

.Footer_footer__socials__2oUcQ a {
    -webkit-transition: all .45s;
    transition: all .45s;
}

.Footer_footer__socials__2oUcQ ul {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

@media screen and (min-width: 1200px) {
    .Footer_footerBottom__content__RSA6m {
        flex-direction: row;
    }
    .Footer_footer__socials__2oUcQ ul {
        justify-content: flex-end;
    }
}

.Footer_footer__socials__2oUcQ ul::before {
    display: none;
}

.Footer_footer__socials__2oUcQ ul li {
    margin-right: 37px;
    margin-bottom: 0;
}

@media screen and (min-width: 1200px) {
    .Footer_footer__socials__2oUcQ ul li {
        margin-right: 17px;
        width: 43px;
        height: 43px;
    }


}


.Footer_footer__V1QIA a {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: #FFCD00;
    text-decoration: none;
}

.Footer_footer__email__2_gPr {
    border: 1px solid rgba(255, 205, 0, .2);
    display: inline-block;
    padding: 5px;
    border-radius: 6px;
    text-decoration: underline !important;
    position: relative;
    -webkit-transition: all .3s;
    transition: all .3s;
    z-index: 1;
}
.Footer_footer__email__2_gPr::before,.Footer_footer__email__2_gPr::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 0;
    display: block;
    -webkit-transition: all .3s;
    transition: all .3s;
    top: 0;
    z-index: -1;
    border-radius: 6px;
    background: rgba(255, 205, 0, .8);
}
.Footer_footer__email__2_gPr::before {
    left: 0;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}
.Footer_footer__email__2_gPr::after {
    right: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}
.Footer_footer__email__2_gPr:hover {
    color: #ffffff;
}
.Footer_footer__email__2_gPr:hover::before {
    width: 50%;
}
.Footer_footer__email__2_gPr:hover::after {
    width: 50%;
}
@media screen and (min-width: 1200px) {
    .Footer_footer__V1QIA a:not(:last-child) {
        margin-right: 20px;
    }
}

.Footer_footer__V1QIA a:hover {
    text-decoration: underline;
}

.Footer_footer__feedback__2f3dt {
    margin-top: 22px;
    display: flex;
    align-items: center;
    /*justify-content: space-between;*/
}

@media screen and (max-width: 1200px) {
    .Footer_footer__feedback__2f3dt {
        flex-direction: column;
        align-items: center;
    }
}

@media screen and (min-width: 1200px) {
    .Footer_footer__feedback__2f3dt {
        /*justify-content: center;*/
    }
    .Footer_footerImg__3qjjV {
        display: block;
        position: absolute;
        right: 10%;
        bottom: 0;
        width: 410px;
        z-index: 4;
    }
}


