.container {
    margin: 0 auto;
    padding: 0 24px;
    max-width: 1280px;
    width: 100%;
}

.about {
    /*opacity: 0;*/
    /*visibility: hidden;*/
    height: 0;
    /*overflow: hidden;*/
    transition: all .25s;
}

.about.pageIsLoaded {
    /*opacity: 1;*/
    /*visibility: visible;*/
    height: auto;
}

.about__title {
    margin-bottom: 30px;
    font-family: 'Syncopate', sans-serif;
    font-weight: bold;
    font-size: 26px;
    line-height: 28px;
    letter-spacing: -0.15px;
    color: #1D223D;
}

.about__subtitle {
    margin-bottom: 15px;
    font-family: 'Tussilago', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    letter-spacing: -0.08px;
    color: #5272FF;
}

.text {
    position: relative;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.05px;
    color: #525252;
    z-index: 2;
}

.text p {
    margin-bottom: 15px;
}

.columns__img {
    position: relative;
    margin-bottom: 15px;
    overflow: hidden;
    height: auto;
    min-height: 200px;
    max-height: none;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% auto;
    background-attachment: unset;
}

.about__center .columns__img,
.about__center .columns__img img {
    height: auto;
    max-height: 650px;
}

.columns__img img {
    width: 100%;
    max-height: 300px;
    object-fit: cover;
    object-position: center;
}

.columns {
    display: flex;
    flex-direction: column;
}

.columns.img_first {
    flex-direction: column-reverse;
}

.fullImg {
    margin-bottom: 15px;
    height: 300px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: -2;
}

.about__top {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-top: 100px;
}

.back_btn__wrapper {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: flex-end;
    padding: 0 24px;
    max-width: 970px;
    width: 100%;
    z-index: 2;
}

.back_btn {
    display: inline-block;
    margin-left: auto;
    padding: 10px 10px 10px 70px;
    font-family: 'Syncopate', sans-serif;
    font-weight: bold;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: -0.04px;
    text-decoration: none;
    color: #5272FF;
    background-image: url('../../assets/img/back_arrow.svg');
    background-repeat: no-repeat;
    background-position: 0% 49%;
    background-size: 60px;
    transition: all .45s;
}

.back_btn:hover {
    padding-left: 100px;
}

.back_btn:active {
    padding-left: 70px;
}

.letters {
    display: none;
}

.about__title,
.about__subtitle,
.animated_top_text,
.columns__img_top {
    animation-delay: 3s;
}

.columns__img_top {
    opacity: 0;
    transition: all 4s ease;
}

.columns__img_top.animate {
    opacity: 1;
}


@media screen and (min-width: 768px) {
    .about__subtitle {
        margin-bottom: 25px;
        max-width: 780px;
    }

    .about__bottom_subtitle {
        max-width: 850px;
    }

    .columns {
        margin-bottom: 25px;
    }

    .columns,
    .columns.img_first {
        flex-direction: row;
    }

    .columns__img {
        margin-bottom: 0;
        width: 50%;
    }

    .text {
        margin-bottom: 0;
        padding-right: 30px;
        width: 50%;
    }

    .columns.img_first .text {
        padding-right: 0;
        padding-left: 30px;
    }

    .fullImg {
        margin-bottom: 25px;
    }
}

@media screen and (min-width: 1200px) {
    .container,
    .back_btn__wrapper {
        padding-left: 0;
        padding-right: 0;
    }

    .container,
    .about {
        position: relative;
    }

    .about::before {
        content: '';
        position: absolute;
        top: 90px;
        left: -345px;
        display: block;
        width: 100%;
        height: 800px;
        /*
        background-image: url('../../assets/img/zphoto.png');
        background-repeat: no-repeat;
        background-position: center left;
        background-size: contain;
         */
        opacity: 0.4;
        z-index: -1;
        /*transition: all .45s;*/
    }

    .columns__img {
        overflow: visible;
        height: 300px;
    }

    .columns__img,
    .fullImg {
        position: relative;
        background-attachment: fixed;
    }

    .letter {
        display: block;
        width: 90px;
        height: 90px;
        overflow: hidden;
    }

    .letters {
        position: absolute;
        display: block;
        width: 90px;
        height: 90px;
    }

    .letters.first {
        left: 0;
        bottom: -80px;
    }
    .letters.first__2 {
        right: 0;
        bottom: -48px;
    }

    .letters.second {
        top: 20px;
        left: 0;
    }

    .letters.third {
        top: 100px;
        right: -45px;
        z-index: -1;
    }

    .letters.fourth {
        top: 100px;
        left: -100px;
    }

    .letters.fifth {
        top: 300px;
        right: 0;
    }
}

@media screen and (min-width: 1400px) {
    .about__top {
        padding-top: 190px;
    }

    .about::before {
        top: 215px;
        left: -350px;
    }

    .back_btn {
        font-size: 12px;
    }

    .about__title {
        margin-bottom: 50px;
        font-size: 48px;
        line-height: 50px;
    }

    .about__subtitle {
        margin-bottom: 40px;
        font-size: 24px;
        line-height: 30px;
    }

    .text {
        padding-right: 50px;
        font-size: 16px;
        line-height: 27px;
    }

    .text p {
        margin-bottom: 25px;
    }

    .columns.img_first .text {
        padding-right: 0;
        padding-left: 50px;
    }

    .columns {
        margin-bottom: 50px;
    }

    .fullImg {
        margin-bottom: 70px;
        height: 790px;
    }
}
