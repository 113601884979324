.mobile_menu {
    position: fixed;
    top: 0;
    right: 0;
    display: block;
    padding-top: 90px;
    width: 80vw;
    height: 100vh;
    background-color: #1D1E44;
    transform: translateX(100%);
    transition: all .45s;
    z-index: 4;
}

.mobile_menu.color {
    background-color: #3061A1;
    height: 205px;
    padding-bottom: 62px;
}
.mobile_menu.color .mobile_menu__container{
    height: 100%;
}
@media screen and (max-width: 370px) {
    .mobile_menu {
        padding-top: 100px;
    }
}

@media screen and (max-width: 575px) and (min-height: 670px) {
    .mobile_menu {
        padding-top: 110px;
    }
}

@media screen and (min-width: 767px) {
    .mobile_menu {
        padding-top: 200px;
    }
}

@media screen and (min-width: 1200px) {
    .mobile_menu {
        opacity: 1;
        visibility: visible;
        padding-top: 0;
        height: auto;
        width: 100vw;
        background: transparent;
        transform: translateY(-100%);
    }
}

.mobile_menu.active {
    transform: translateX(0);
}

@media screen and (min-width: 1200px) {
    .mobile_menu.active {
        transform: translateY(0);
    }

    .mobile_menu.scrolled {
        background: rgba(82, 114, 255, 1);
        z-index: 4;
    }
}

.mobile_menu__main {
    margin-bottom: 120px;
}

@media screen and (max-width: 575px) and (min-height: 670px) {
    .mobile_menu__main {
        margin-bottom: 160px;
    }
}

@media screen and (min-width: 1200px) {
    .mobile_menu__main {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 0;
    }

    .mobile_menu__main.onTop li:last-child {
        margin-right: 0;
    }
}

.mobile_menu__main li {
    cursor: pointer;
}

@media screen and (min-width: 1200px) {
    .mobile_menu__main li {
        position: relative;
        margin-right: 30px;
        transition: all .25s;
    }

    .mobile_menu__main li::before {
        content: '';
        position: absolute;
        bottom: 25px;
        left: 0;
        display: block;
        width: 0;
        height: 1px;
        background-color: #FFCD00;
        transition: all .45s;
    }

    .mobile_menu__main li:last-child {
        margin-right: 230px;
    }

    .mobile_menu__main li:not(:last-child) {
        margin-bottom: 0;
    }
}

.mobile_menu__main a {
    display: block;
    padding: 15px 0;
    font-family: 'Tussilago', sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 15px;
    text-decoration: none;
    text-align: right;
    letter-spacing: -0.04px;
    color: #E3E3E3;
    transition: all .45s;
}

@media screen and (min-width: 992px) {
    .mobile_menu__main a {
        font-size: 28px;
        line-height: 34px;
    }
}

@media screen and (min-width: 1200px) {
    .mobile_menu__main a {
        padding: 33px 0;
        font-size: 12px;
        line-height: 12px;
        color: #E3E3E3;
        /*opacity: 0.5;*/
    }

    .mobile_menu__main li:hover::before,
    .mobile_menu__main li.active::before {
        width: 100%;
    }
}

.mobile_menu__main li span {
    transition: all .45s;
}

@media screen and (min-width: 1200px) {
    .mobile_menu__main li:hover span,
    .mobile_menu__main li.active span {
        display: block;
        opacity: 1;
        /*transform: translateY(-3px);*/
    }
}

.mobile_menu__secondary a {
    display: block;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 18px;
    line-height: 36px;
    text-decoration: none;
    text-align: left;
    color: #ffffff;
}

.mobile_menu__main li.active a,
.mobile_menu__main li:hover a {
    opacity: 1;
}

@media screen and (min-width: 1200px) {
    .mobile_menu__secondary {
        display: none;
    }
}

.mobile_menu__socials {
    margin-top: 70px;
}

@media screen and (min-width: 1200px) {
    .mobile_menu__socials {
        display: none;
    }
}

.mobile_menu__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}



