.stores {
    transform: translateY(0);
    padding-bottom: 50px;
    /*background-color: #F7F7F7;*/
    overflow: hidden;
}
.stores__header span {
    font-size: 16px;
    line-height: 27px;
    letter-spacing: -.5px;
    color: rgba(82,82,82,1);
    display: inline-block;
    margin-bottom: 10px;
}

@media screen and (min-width: 992px) {
    .stores {
        padding-top: 25px;
    }
}

@media screen and (min-width: 1200px) {
    .stores {
        padding-top: 120px;
        padding-bottom: 100px;
    }
}

.stores__list {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 55px;
    grid-gap: 10px;
}

.stores__list li {
    grid-row: span 2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 155px;
    height: auto;
    background-color: #ffffff;
    box-shadow: 0 1px 10px 0 rgba(24,24,24,0.16);
    opacity: 0;
    transform: translateY(100%);
    visibility: hidden;
    transition: all .15s, transform .45s;
}

.stores__list li.animate {
    opacity: 1;
    transform: translateY(0%);
    visibility: visible;
}

.stores__list li:first-child {
    grid-row: 2 / 4;
}

@media screen and (min-width: 768px) {
    .stores__list {
        grid-auto-rows: 75px;
    }
}

@media screen and (min-width: 1200px) {
    .stores__list {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 30px;
        margin-top: -70px;
    }

    .stores__list li {
        box-shadow: 0 2px 50px 0 rgba(24,24,24,0.05);
        max-width: none;
    }

    .stores__list li:hover {
        transform: scale(1.05);
        box-shadow: 0 2px 50px 0 rgba(24,24,24,0.2);
    }

    .stores__list li:hover .stores__list_span::before {
        opacity: 1;
        transform: translateX(0);
    }

    .stores__list li:active {
        box-shadow: 0 2px 50px 0 rgba(24,24,24,0.05);
    }

    .stores__list li:first-child {
        grid-row: 3 / 5;
    }

    .stores__list li:nth-child(2) {
        grid-row: 2 / 4;
    }

    .stores__list li:nth-child(3) {
        grid-row: 4 / 6;
    }

    .stores__list li:nth-child(4) {
        grid-row: 1 / 3;
        grid-column: 3;
    }

    .stores__list li:nth-child(5) {
        grid-column: 3;
    }

    .stores__list_span {
        position: relative;
    }

    .stores__list_span::before {
        content: '';
        position: absolute;
        bottom: -50px;
        left: 0;
        display: block;
        width: 70px;
        height: 20px;
        background-image: url('../../assets/img/arrows/arrow_mob_right_blue.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        transform: translateX(-100%);
        opacity: 0;
        transition: all .45s;
    }
}

@media screen and (min-width: 1400px) {
    .stores__list {
        grid-auto-rows: 115px;
        margin-top: -100px;
    }
}

.stores__list a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

li.stores__list_seller {
    position: relative;
    border: 1px solid rgba(82,114,255,1);
    background: transparent;
    box-shadow: 0 2px 50px 0 rgba(24,24,24,0.05);
}

@media screen and (min-width: 577px) {
    li.stores__list_seller br {
        display: none;
    }
}

@media screen and (min-width: 1200px) {
    li.stores__list_seller:hover {
        transform: scale(1);
    }
}

.stores__list_seller a {
    padding-left: 20px;
    font-family: 'Tussilago', sans-serif;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: -0.04px;
    color: rgba(82,114,255,1);
}

@media screen and (min-width: 768px) {
    .stores__list_seller a {
        font-size: 14px;
        line-height: 16px;
    }
}


.stores__list_circle {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
}

.stores__list_circle::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 50%;
    height: 100%;
    border-radius: 0 100% 100% 0 / 0 50% 50% 0;
    background: rgba(82,114,255,1);
    transform-origin: center left;
    transition: all .45s;
}

@media screen and (max-width: 350px) {
    .stores__list_circle {
        left: -5px;
    }
}

@media screen and (min-width: 577px) {
    .stores__list_circle {
        left: 30px;
    }
}

.stores__list li {

}

.stores__list li img {
    height: 30px;
    width: 100px;
    object-fit: contain;
}

.stores__list li:nth-child(4) img {
    height: 15px;
}

@media screen and (min-width: 1400px) {
    .stores__list li img {
        height: 50px;
        width: 165px;
    }

    .stores__list li:nth-child(4) img {
        height: 30px;
    }
}
