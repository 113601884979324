.policy {
    background: #F7F7F7;
    padding-top: 50px;
    margin-bottom: 30px;
}

.policy__container {
    background-color: #FFFFFF;
    position: relative;
    z-index: 3;
    padding: 40px;

}

.policy__container_hide {
    z-index: 2 !important;
}

.policy__title {
    font-family: 'Tussilago', sans-serif;
    color: #1D223D;
    font-weight: bold;
    margin-bottom: 20px;
    font-size: 30px;
    line-height: 36px;
}

.policy__info {
    margin-bottom: 30px;
}

.policy__text {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -.5px;
    color: #525252;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 20px;
}

.policy__text:last-child {
    margin-bottom: 0;
}

.policy__text a {
    color: #5272FF;
    border-bottom: 1px solid #5272FF;
    text-decoration: none;
}

.policy__text_strong {
    font-weight: bold;
    color: #525252;
}

.policy__name {
    color: #5272FF;
    font-family: 'Tussilago', sans-serif;
    font-weight: bold;
    margin-bottom: 10px;
}

.policy__list {
    margin-left: 30px;
}

.policy__item {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -.5px;
    font-family: 'Montserrat', sans-serif;
    color: #525252;
    list-style: disc;
}

@media screen and (min-width: 1200px) {
    .policy {
        margin-top: 135px;
        margin-bottom: 60px;
    }


    .policy__container {
        box-shadow: 0 0 36px 0 rgba(0, 0, 0, 0);
        padding: 90px 185px 120px;
    }


    .policy__title {
        font-size: 48px;
        line-height: 55px;
        letter-spacing: -.15px;
        margin-bottom: 40px;
    }

    .policy__name {
        font-size: 24px;
        line-height: 30px;
        letter-spacing: -.08px;
    }

    .policy__item {

    }
}
