.homePage__comments {
    background-color: #F7F7F7;
}

.homePage {
    /*opacity: 0;*/
    /*visibility: hidden;*/
    /*transition: all .45s;*/
    height: 0;
    overflow: hidden;
    /*background-color: #F7F7F7;*/
}

.homePage.pageIsLoaded {
    opacity: 1;
    height: auto;
    visibility: visible;
}
