.socials {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    width: max-content;
}

.socials__item {
    position: relative;
    margin-bottom: 30px;
    width: 23px;
    height: 23px;
    border-radius: 50%;
    opacity: 0.34;
    transition: all .45s;
}

.socials__item:last-child {
    margin-right: 0;
}

.socials__item:hover {
    opacity: 1;
}

.socials__item a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.socials__item img {
    max-width: 100%;
}