.header {
    position: fixed;
    top: 0;
    left: 0;
    padding: 27px 0;
    width: 100vw;
    z-index: 3;
}
.header__color {
    background-color: #3061A1;
    z-index: 4;

}
@media screen and (min-width: 1200px){
    .header {
        padding: 24px 0;
        background: transparent;
    }
    .header__color {
        background-color: transparent;
    }
}

.header__container {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

@media screen and (min-width: 1600px) {
    .header__container {
        position: relative;
    }
}

.burger__wrapper {
    position: fixed;
    top: -20px;
    right: -10px;
    width: 100px;
    height: 100px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
    transition: all .25s;
}

@media screen and (min-width: 1200px) {
    .burger__wrapper {
        top: -35px;
        right: -30px;
        width: 150px;
        height: 150px;
        opacity: 0;
        visibility: hidden;
    }

    .burger__wrapper.show {
        opacity: 1;
        visibility: visible;
    }
}

@media screen and (min-width: 1400px) {
    .burger__wrapper {
        right: 40px;
    }
}

.burger {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    transform-origin: center;
    cursor: pointer;
}

/*.burger.active {*/
/*    background-color: rgba(82,114,255,1);*/
/*}*/

.burger__circle::after,
.burger__circle::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 50%;
    height: 100%;
    border-radius: 0 100% 100% 0 / 0 50% 50% 0;
    background: rgba(82,114,255,1);
    -webkit-transition: all .45s;
    transition: all .45s;
    z-index: -1;
}

.burger__circle::before {
    -webkit-transform-origin: 2% 50%;
    -ms-transform-origin: 2% 50%;
    transform-origin: 2% 50%;
}

.burger.active .burger__circle::before {
    transform: rotate(180deg);
}

/*.burger.active .burger__circle {*/
/*    background: rgba(82,114,255,1);*/
/*}*/

@media screen and (min-width: 1200px) {
    .burger__wrapper:hover .burger__title {
        transform: translateY(-50%) translateX(5px);
    }
}

@media screen and (min-width: 1600px) {
    .burger__wrapper {
        position: absolute;
        right: -20px;
        top: -60px;
    }
}

.burger.change_color .burger__title::before,
.burger.change_color .burger__title::after {
    color: rgba(82,114,255,1);
}

/*.burger::before,*/
.burger__circle {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /*right: 0;*/
    display: block;
    /*width: 50%;*/
    width: 100%;
    height: 100%;
    border-radius: 50%;
    /*border-radius: 0 100% 100% 0 / 0 50% 50% 0;*/
    /*background: rgba(82,114,255,1);*/
    /*border: 1px solid rgba(82,114,255,1);*/
    transform-origin: center left;
    transition: width .45s, height .45s, border-width .45s;
    z-index: -1;
}

@media screen and (min-width: 1200px) {
    .burger__wrapper:hover .burger__circle {
        border-width: 2px;
        width: 150%;
        height: 150%;
    }

    .burger__wrapper:active .burger__circle,
    .burger.active .burger__circle {
        border-width: 1px;
        width: 100%;
        height: 100%;
    }
}

.burger__bar_wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.burger__title {
    position: absolute;
    left: -170%;
    top: 50%;
    transform: translateY(-50%);
    font-family: 'Syncopate', sans-serif;
    font-weight: 700;
    font-size: 11px;
    line-height: 11px;
    color: transparent;
    overflow: hidden;
    transition: all .45s;
}

.burger.active .burger__title::before,
.burger.active .burger__title::after {
    color: #ffffff;
}

.burger__title::before,
.burger__title::after {
    content: 'menu';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-family: 'Syncopate', sans-serif;
    font-weight: 700;
    font-size: 11px;
    line-height: 11px;
    color: #ffffff;
    transition: all .45s;
}

.burger.active .burger__title::before {
    transform: translate(-50%,100%);
}

.burger__title::after {
    content: 'close';
    transform: translate(-50%,100%);
}

.burger.active .burger__title::after {
    transform: translate(-50%,-50%);
}

.burger__bar {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: block;
    width: 11px;
    height: 3px;
    background-color: #E3E3E3;
    transition: all .45s;
}

/*.burger.change_color .burger__bar {*/
/*    background-color: rgba(82,114,255,1);*/
/*}*/

.burger.change_color.active .burger__bar {
    background-color: #E3E3E3;
}

.burger.active .burger__bar {
    transform: translate(-50%,-50%) rotate(45deg);
}

.burger__bar:nth-child(2) {
    transform: translate(-50%,-50%) rotate(-90deg);
}

.burger.active .burger__bar:nth-child(2) {
    transform: translate(-50%,-50%) rotate(-45deg);
}

@media screen and (min-width: 1200px) {
    .burger__title {
        left: -200%;
    }
}

/* COOKIES */
.cookies {
    position: fixed;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    bottom: 0;
    left: 0;
    padding: 10px 15px 10px 15px;
    background: radial-gradient(circle, rgba(88,90,156,1) 0%, rgba(38,40,90,1) 100%);
    transform: translateY(200%);
    opacity: 0;
    transition: all .45s 6s;
    z-index: 10;
}

.cookies.hide {
    opacity: 0;
    visibility: hidden;
}

.cookies.pageLoaded {
    transform: translateY(0);
    opacity: 1;
}

.cookies.change_color {
    background: radial-gradient(circle, rgba(88,90,156,1) 0%, rgba(38,40,90,1) 100%);
}

.cookies__text {
    flex: 1;
    margin-bottom: 10px;
}

.cookies__text p {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.04px;
    color: #FFFFFF;
}

.cookies__btn {
    padding: 10px;
    font-family: 'Syncopate', sans-serif;
    font-weight: 400;
    font-size: 10px;
    line-height: 11px;
    letter-spacing: -0.03px;
    text-transform: uppercase;
    border-radius: 12px;
    outline: none;
    border: none;
    width: 100%;
    color: #1E1F3F;
    background-color: #FFFFFF;
    cursor: pointer;
    transition: all .45s;
}

.cookies__btn:hover {
    box-shadow: 0 0 10px 10px rgba(255,255,255,0.1);
}

.cookies__btn:active {
    box-shadow: none;
}

@media screen and (min-width: 1200px) {
    .cookies {
        width: 330px;
        background-color: rgba(255,255,255,1);
    }
}
