.artList {
    display: flex;
    flex-wrap: wrap;
}

@media screen and (min-width: 1200px) {
    .artList {
        flex: 1;
    }
}

.artList.lock,
.artList__specifications.lock {
    pointer-events: none;
    cursor: default;
}

.artList__container {
    position: relative;
    margin-bottom: 5px;

}

@media screen and (min-width: 1200px) {
    .artList__container {
        display: flex;
        margin-bottom: 25px;
    }
}

.artList li {
    margin-right: 15px;
    margin-bottom: 25px;
    cursor: pointer;
}

.artList li,
.artList li figure {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    transition: all .45s;
}

.artList li::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 0;
    height: 1px;
    background-color: rgba(82,114,255,1);
    transition: all .45s;
}

.artList li.active figure {
    transform: translateY(-12px);
}

.artList li.active::before {
    width: 100%;
}

.artList li img {
    width: 100%;
}

.artList__text {
    margin: 0 auto;
    text-align: center;
    max-width: 230px;
}

@media screen and (min-width: 376px) {
    .artList__text {
        max-width: 400px;
    }
}

.artList__text p {
    font-family: 'Istok Web', sans-serif;
    font-weight: 700;
    font-style: italic;
    font-size: 16px;
    line-height: 23px;
    color: rgba(89,89,89,1);
}

@media screen and (min-width: 767px) {
    .artList__text p {
        font-size: 18px;
    }
}

.artList__btn {
    margin-top: 30px;
}

@media screen and (min-width: 767px) {
    .artList__btn {
        margin-top: 30px;
    }
}

/* Modal */
.artList__modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100vw;
    background-color: #fff;
    opacity: 0;
    visibility: hidden;
    transition: all .45s;
    z-index: 2;
}

.artList__modal.show {
    opacity: 1;
    visibility: visible;
}

.artList__modal_header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 23px 0;
}

.artList__modal_header p {
    font-family: 'Avenir', sans-serif;
    font-weight: 900;
    font-size: 16px;
    line-height: 22px;
    color: rgba(0,139,255,0.3);
    letter-spacing: 2.83px;
    text-transform: uppercase;
}

.artList__modal_chosen {
    position: relative;
    margin-left: 15px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    transform-origin: center;
}

.artList__modal_chosen::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: block;
    width: 47px;
    height: 47px;
    border-radius: 50%;
    border: 0.95px solid rgba(0,139,255,1);
}

.artList__modal_chosen::after {
    content: '';
    position: absolute;
    right: -40px;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
    display: block;
    width: 15px;
    height: 15px;
    background-image: url('../../../assets/img/arrow_down.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transition: all .45s;
}

.artList__modal_chosen img {
    width: 100%;
}
.artList__modal_body {
    padding: 45px 0 15px;
}

.artList__modal_slider li img {
    width: 50px;
}

.artList__modal_slider * {
    outline: none;
}

/* End Modal*/

.artList__title {
    margin-bottom: 20px;
    font-family: "Tussilago", sans-serif;
    font-weight: 700;
    font-size: 12px;
    line-height: 19px;
    color: rgba(119,119,143,1);
    text-transform: uppercase;
}

@media screen and (min-width: 1200px) {
    .artList__title {
        margin-bottom: 0;
        width: 37%;
        padding-right: 10px;
    }
}

@media screen and (min-width: 1400px) {
    .artList__specifications {
        padding-left: 0;
    }
}

.artList__specifications li {
    position: relative;
    padding-left: 0;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 15px;
    line-height: 29px;
    letter-spacing: -0.05px;
    text-align: left;
    color: rgba(40,40,58,1);
    transition: all .45s;
    padding-left: 15px;
}
.artList__specifications li.first {
    padding-left: 0;
}
.artList__specifications li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 7px;
    height: 1px;
    background-color: rgba(82,114,255,1);
}
.artList__specifications li.first::before {
    display: none;
}
.artList__specifications li span {
    transition: all .45s;
}

.collection__options_title {
    margin: 17px 0 20px;
}

@media screen and (min-width: 1200px) {
    .collection__options_wrapper {
        display: flex;
        align-items: flex-end;
        order: -1;
        margin-bottom: 50px;
    }

    .collection__options_title {
        display: block;
        margin: 0;
    }

    .artList__specifications {
        padding-left: 20px;
    }
    .artList__specifications li.first {
        padding-left: 0;
    }
    .artList__specifications li {
        padding-left: 30px;
    }

}

.collection__options {
    display: flex;
    justify-content: center;
    margin-top: 12px;
    margin-bottom: 20px;
}

@media screen and (min-width: 1200px) {
    .collection__options {
        position: static;
        transform: none;
        top: 0;
        left: 0;
        width: auto;
        margin-bottom: 0;
    }
}

.collection__option {
    position: relative;
    font-family: 'Tussilago', sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    line-height: 19px;
    color: rgba(40,40,58,1);
    transition: all .45s;
    cursor: pointer;
}

.collection__option:not(:last-child){
    margin-right: 30px;
}

.collection__option span {
    display: block;
    transition: all .45s;
}

.collection__option.active {
    pointer-events: none;
}

.collection__option.active span {
    transform: translateY(-8px);
    color: rgba(82,114,255,1);
}

.collection__option::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 0;
    height: 1px;
    background-color: rgba(82,114,255,1);
    transition: all .45s;
}

.collection__option.active::before {
    width: 100%;
}

.artList__wrapper {
    /*position: relative;*/
    z-index: 2;
}

@media screen and (min-width: 1200px) {
    .artList__specifications_wrapper {
        display: flex;
    }

    .artList__wrapper {
        display: flex;
        flex-direction: column;
    }
}
